import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-cgu',
  templateUrl: './meepik-cgu.component.html',
  styleUrls: ['./meepik-cgu.component.scss']
})
export class MeepikCguComponent {

}
