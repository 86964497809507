import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-rigth-arrow-button',
  templateUrl: './meepik-rigth-arrow-button.component.html',
  styleUrls: ['./meepik-rigth-arrow-button.component.scss']
})
export class MeepikRigthArrowButtonComponent {
  @Input({ required: true }) width!: string;
  @Input({ required: true }) height!: string;
  @Input({ required: true }) isRight!: boolean;

  btnProps = {
    backGroundColor: 'none',
    shadowColor: 'none',
    textColor: 'white',
    font: 'spot',
    fontSize: '0'
  };
}
