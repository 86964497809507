import { Component } from '@angular/core';
import { CarouselService } from './meepik-carrousel.service';
import { MeepikBlogCardComponent } from '../meepik-blog-card/meepik-blog-card.component';
import { carouselAnimation } from './meepik-carrousel.animation'

@Component({
  selector: 'app-meepik-carrousel',
  templateUrl: './meepik-carrousel.component.html',
  styleUrl: './meepik-carrousel.component.scss',
})
export class MeepikCarrouselComponent {
  components = [
    MeepikBlogCardComponent,
    MeepikBlogCardComponent,
    MeepikBlogCardComponent,
    MeepikBlogCardComponent,
  ];

  currentIndex = 0;

  constructor(private carouselService: CarouselService) {}

  prev(): void {
    this.currentIndex = this.carouselService.getPrevIndex();
  }

  next(): void {
    this.currentIndex = this.carouselService.getNextIndex();
  }
}
