import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalParamService } from 'src/api/helper/getParams';
import { MeepikUserService } from 'src/api/helper/userService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-meepik-menu',
  templateUrl: './meepik-menu.component.html',
  styleUrls: ['./meepik-menu.component.scss'],
})
export class MeepikMenuComponent implements OnInit {
  isAuthenticated: boolean = false;
  pglobalParamService: GlobalParamService;
  constructor(
    private globalParamService: GlobalParamService,
    private userService: MeepikUserService,
  ) {
    this.pglobalParamService = globalParamService;
  }
  ngOnInit(): void {
    this.userService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;
    });
  }

  getCurrentPath() {
    return window.location.pathname.replace('/', '') !== ''
      ? (window.location.pathname.replace('/', '').toUpperCase() as MenuOption)
      : 'HOME';
  }
  login() {
    this.isAuthenticated ? this.userService.logout() : this.userService.login();
  }
  width: string = 'auto';
  height: string = '50px';
  btnProps = {
    backGroundColor: 'transparent',
    shadowColor: 'none',
    textColor: '#ffffff',
    font: 'spot',
    fontSize: '16px',
  };
  btnSingUPProps = {
    backGroundColor: '#ffce57',
    shadowColor: '#d27f1e',
    textColor: '#431f73',
    font: 'spot',
    fontSize: '20px',
  };
  styles = {
    menuButton: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '15px',
      zIndex: 10,
    },
    menuText: {
      fontSize: '15px',
      paddingLeft: '2px',
    },
  };

  selectedMenu: MenuOption = this.getCurrentPath();
  goProfile() {
    window.location.replace(environment.authunUrl);
  }
  getColor(option: MenuOption): string {
    var color = '#ffffff';
    if (this.selectedMenu === option) {
      color = '#FFCE57';
    }
    return color;
  }
  changeMenu(option: MenuOption) {
    this.selectedMenu = option;
  }
}
type MenuOption = 'HOME' | 'DOWNLOAD' | 'SHOP' | 'FAQ';
