<div id="liveUsers" class="container">
  <div
    [ngStyle]="{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }"
  >
    <div [ngStyle]="{ width: '960px' }">
      <div [ngStyle]="{ display: 'flex' }">
        <div
          [ngStyle]="{
            display: 'flex',
            alignItems: 'baseline'
          }"
        >
          <app-meepik-title
            [text]="'home.liveUserBlock.title.left' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'home.liveUserBlock.title.right' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="true"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'.'"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
          />
        </div>
      </div>
      <app-meepik-separator [width]="'4%'" />
      <div [ngStyle]="{ display: 'flex' }">
        <app-meepik-text-area>
          <div [ngStyle]="{ paddingTop: '9px' }">
            {{ "home.liveUserBlock.title.description" | translate }}
          </div>
        </app-meepik-text-area>
      </div>
    </div>

    <div
      [ngStyle]="{
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        paddingTop: '75px',
        width: '900px'
      }"
    >
      @for (card of cards; track $index) {
      <app-meepik-live-user-button [cardInfo]="card" />
      }
    </div>
  </div>
</div>
