<div class="container">
  <div class="textCard">
    <app-meepik-text-area>
      <div class="text">
        <h1>Mentions légales :</h1>
        <h2>Éditeur :</h2>
        <p>MEEPIK SARL</p>
        <p>Société à responsabilité limitée au capital social de 10€</p>
        <p>SIRET: 98009906300011 / RCS MARSEILLE 980 099 063</p>
        <p>Siège social: 6 Che De La Parette, 13012 Marseille, France</p>

        <p>
          - Directeur de publication: Yoann JEGOUX (yoann.jegoux&#64;meepik.com)
        </p>
        <p>
          - Délégué à la protection des données: Jules PAYAGE (dpo&#64;meepik.com)
        </p>

        <h2>Marque :</h2>
        <p>
          MEEPIK est une marque commerciale exploitée exclusivement par MEEPIK
          SARL
        </p>
        <h2>Hébergements / Services</h2>
        <h3>Hébergeur :</h3>

        <p>OVH SASU</p>
        <p>
          Société par Action Simplifié à associé unique au capital social de 10
          174 560 €
        </p>
        <p>SIRET: 42476141900045 / RCS Lille Métropole 424 761 419 00045</p>
        <p>Siège social: 2 Rue Kellermann, 59100 Roubaix, France</p>
        <h3>DNS/CDN :</h3>

        <p>BunnyWay d.o.o</p>
        <p>Enregistrement: 7297513000</p>
        <p>Siège Social: Dynajska cesta 165, 1000, Ljubljana, Slovenie</p>

        <h3>Email/Newsletter :</h3>

        <p>Infomaniak Network SA</p>
        <p>
          Numéro IDE: CHE-103.167.648 RC Canton de Genève CH-660.0.059.996-1
        </p>
        <p>
          Siège social: 25 Rue Eugène Marziano, 1227 Les Acacias, Genève, Suisse
        </p>

        <h3>Gestionnaire de paiements :</h3>

        <p>Stripe Payments Europe, Ltd (SPEL)</p>
        <p>Société à Capitaux Privés</p>
        <p>
          Siège social: 354 Oyster Point Boulevard, San Francisco, California,
          94103, United States of America
        </p>

        <h3>Avis Clients :</h3>

        <p>Trustpilot A/S</p>
        <p>
          Enregistrement: 30276582 Tribunaux d'Angleterre et du Pays de Galles
        </p>
        <p>
          Siège Social: Trustpilot Limited, 5th Floor, The Minster Building, 21
          Mincing Lane, London, United Kingdom, EC3R 7AG
        </p>
      </div>
    </app-meepik-text-area>
  </div>
</div>
