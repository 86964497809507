@if (notificationService.isOpen) {
<div class="notification" *ngIf="notificationService.isOpen">
  <div class="notificationHeader">
    <div class="infoTitle">INFORMATION</div>
    <div
      class="backArrowNotification"
      (click)="notificationService.isOpen = false"
    >
      <img src="../assets/img/bx_arrow-back.png" alt="" />
    </div>
  </div>
  <div class="separator"></div>
  <div class="notificationText">
    {{ notificationService.notificationText }}
  </div>
</div>
}
