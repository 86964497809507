import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-meepik-switch',
  templateUrl: './meepik-switch.component.html',
  styleUrls: ['./meepik-switch.component.scss'],
})
export class MeepikSwitchComponent {
  constructor(private translate: TranslateService) {
  }
  isEn(): boolean {
    return this.translate.currentLang === 'en';
  }
  onClick() {
    if (this.translate.currentLang === 'en') {
      this.translate.use('fr');
      localStorage.setItem('meepik_lang', 'fr');
    } else {
      this.translate.use('en');
      localStorage.setItem('meepik_lang', 'en');
    }
  }
}
