import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-meepik-use-mic-button',
  templateUrl: './meepik-use-mic-button.component.html',
  styleUrls: ['./meepik-use-mic-button.component.scss'],
})
export class MeepikUseMicButtonComponent {
  @Input({ required: true }) width!: string;
  @Input({ required: true }) height!: string;

  btnProps = {
    backGroundColor: '#f0f0f0',
    shadowColor: '#c3c3c3',
    textColor: '#4B357F',
    font: 'spot',
    fontSize: '27px',
  };
}
