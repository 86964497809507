import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-login',
  templateUrl: './meepik-login.component.html',
  styleUrls: ['./meepik-login.component.scss']
})
export class MeepikLoginComponent {

}
