import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-learn-more-vtuber',
  templateUrl: './meepik-learn-more-vtuber.component.html',
  styleUrls: ['./meepik-learn-more-vtuber.component.scss'],
})
export class MeepikLearnMoreVtuberComponent {
  blogCards = [
    {
      imgUrl: '',
      author: 'Ariat',
      date: '25/09/2023',
      title:
        'Using an avatar in your online content creation : an unsuspected asset at your disposal',
    },
    {
      imgUrl: '',
      author: 'Ariat',
      date: '25/09/2023',
      title:
        'Streaming through an animated avatar… strange ! Or is it, really …?',
    },
    {
      imgUrl: '',
      author: 'Ariat',
      date: '25/09/2023',
      title:
        'Streaming through an animated avatar… strange ! Or is it, really …?',
    },
  ];
}
