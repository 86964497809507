<div class="container">
  <app-meepik-separator [width]="'100%'" />
  <div class="title">
    <app-meepik-title
      [text]="'Meepik 2023-2024© Inc.'"
      [textColor]="'#FFCE57'"
      [isOutline]="false"
      [fontSize]="'25px'"
    />
  </div>
  <div class="titleContainer title white">
    <a class="title white" routerLink="/cgu" style="text-decoration: none">
      {{ "footer.title.tos" | translate }}
    </a>
    <p class="title white separator">-</p>
    <a class="title white" routerLink="/legals" style="text-decoration: none">
      {{ "footer.title.legals" | translate }}
    </a>
    <p class="title white separator">-</p>
    <a class="title white" routerLink="/privacy" style="text-decoration: none">
      {{ "footer.title.privacy" | translate }}
    </a>
    <p class="title white separator">-</p>
    <a
      class="title white"
      style="text-decoration: none"
      href="https://discord.gg/hgVz3rCESk"
      target="_blank"
    >
      {{ "footer.title.support" | translate }}
    </a>
    <p class="title white separator">-</p>
    <a
      class="title white"
      style="text-decoration: none"
      href="mailto:contact@meepik.com"
    >
      {{ "footer.title.contact" | translate }}
    </a>
  </div>
</div>
