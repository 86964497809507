import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MeepikTitleComponent } from './atoms/meepik-title/meepik-title.component';
import { MeepikMeeComponent } from './atoms/meepik-mee/meepik-mee.component';
import { MeepikLiveUserButtonComponent } from './molecules/meepik-live-user-button/meepik-live-user-button.component';
import { MeepikBannerComponent } from './organisms/meepik-banner/meepik-banner.component';
import { MeepikBlogCardComponent } from './molecules/meepik-blog-card/meepik-blog-card.component';
import { MeepikMenuComponent } from './molecules/meepik-menu/meepik-menu.component';
import { MeepikTextAreaComponent } from './atoms/meepik-text-area/meepik-text-area.component';
import { MeepikBecomeAvatarComponent } from './template/meepik-become-avatar/meepik-become-avatar.component';
import { MeepikCreateYourMeeComponent } from './template/meepik-create-your-mee/meepik-create-your-mee.component';
import { MeepikInstallOnObsComponent } from './template/meepik-install-on-obs/meepik-install-on-obs.component';
import { MeepikGoLiveComponent } from './template/meepik-go-live/meepik-go-live.component';
import { MeepikLearnMoreVtuberComponent } from './template/meepik-learn-more-vtuber/meepik-learn-more-vtuber.component';
import { MeepikLiveUsersComponent } from './template/meepik-live-users/meepik-live-users.component';
import { MeepikLoginComponent } from './organisms/meepik-login/meepik-login.component';
import { MeepikButtonComponent } from './atoms/meepik-button/meepik-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MeepikDownloadButtonComponent } from './molecules/meepik-download-button/meepik-download-button.component';
import { MeepikUseMicButtonComponent } from './molecules/meepik-use-mic-button/meepik-use-mic-button.component';
import { MeepikSeparatorComponent } from './atoms/meepik-separator/meepik-separator.component';
import { MeepikWindowsIconComponent } from './atoms/icons/meepik-windows-icon/meepik-windows-icon.component';
import { MeepikMicIconComponent } from './atoms/icons/meepik-mic-icon/meepik-mic-icon.component';
import { MeepikLogoComponent } from './atoms/meepik-logo/meepik-logo.component';
import { MeepikHomeIconComponent } from './atoms/icons/meepik-home-icon/meepik-home-icon.component';
import { MeepikShopIconComponent } from './atoms/icons/meepik-shop-icon/meepik-shop-icon.component';
import { MeepikDownloadIconComponent } from './atoms/icons/meepik-download-icon/meepik-download-icon.component';
import { MeepikEmotionalButtonComponent } from './molecules/meepik-emotional-button/meepik-emotional-button.component';
import { MeepikAngryIconComponent } from './atoms/icons/meepik-angry-icon/meepik-angry-icon.component';
import { MeepikSmileIconComponent } from './atoms/icons/meepik-smile-icon/meepik-smile-icon.component';
import { MeepikDeathIconComponent } from './atoms/icons/meepik-death-icon/meepik-death-icon.component';
import { MeepikLoveIconComponent } from './atoms/icons/meepik-love-icon/meepik-love-icon.component';
import { MeepikMeeMascotteComponent } from './atoms/meepik-mee-mascotte/meepik-mee-mascotte.component';
import { MeepikMeeMangaComponent } from './atoms/meepik-mee-manga/meepik-mee-manga.component';
import { MeepikTryEmotionTextComponent } from './atoms/meepik-try-emotion-text/meepik-try-emotion-text.component';
import { MeepikReactingVoiceTextComponent } from './atoms/icons/meepik-reacting-voice-text/meepik-reacting-voice-text.component';
import { MeepikArrowRightIconComponent } from './atoms/icons/meepik-arrow-right-icon/meepik-arrow-right-icon.component';
import { MeepikArrowLeftIconComponent } from './atoms/icons/meepik-arrow-left-icon/meepik-arrow-left-icon.component';
import { MeepikRigthArrowButtonComponent } from './molecules/meepik-rigth-arrow-button/meepik-rigth-arrow-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MeepikReviewCardComponent } from './molecules/meepik-review-card/meepik-review-card.component';
import { MeepikStarIconComponent } from './atoms/icons/meepik-star-icon/meepik-star-icon.component';
import { MeepikParallaxComponent } from './organisms/meepik-parallax/meepik-parallax.component';
import { CommonModule } from '@angular/common';
import { MeepikDiscordBannerComponent } from './organisms/meepik-discord-banner/meepik-discord-banner.component';
import { MeepikDiscordButtonComponent } from './molecules/meepik-discord-button/meepik-discord-button.component';
import { MeepikDiscordIconComponent } from './atoms/icons/meepik-discord-icon/meepik-discord-icon.component';
import { MeepikFooterComponent } from './organisms/meepik-footer/meepik-footer.component';
import { MeepikCguComponent } from './page/meepik-cgu/meepik-cgu.component';
import { RouterModule, Routes } from '@angular/router';
import { MeepikMainComponent } from './page/meepik-main/meepik-main.component';
import { HttpClientModule } from '@angular/common/http';
import { MeepikShopComponent } from './page/meepik-shop/meepik-shop.component';
import { MeepikFaqComponent } from './page/meepik-faq/meepik-faq.component';
import { MeepikFaqQuestionComponent } from './organisms/meepik-faq-question/meepik-faq-question.component';
import { MeepikPaymentSuccessComponent } from './page/meepik-payment-success/meepik-payment-success.component';
import { MeepikPaymentCancelComponent } from './page/meepik-payment-cancel/meepik-payment-cancel.component';
import { MeepikArrowComponent } from './atoms/icons/meepik-arrow/meepik-arrow.component';
import { MeepikPrivacyComponent } from './page/meepik-privacy/meepik-privacy.component';
import { MeepikLegalsComponent } from './page/meepik-legals/meepik-legals.component';
import {
  AbstractSecurityStorage,
  AuthModule,
  DefaultLocalStorageService,
  LogLevel,
} from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { MeepikCryIconComponent } from './atoms/icons/meepik-cry-icon/meepik-cry-icon.component';
import { MeepikNotificationDrawerComponent } from './atoms/meepik-notification-drawer/meepik-notification-drawer.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MeepikSwitchComponent } from './atoms/meepik-switch/meepik-switch.component';
import { MeepikTryEmotionFrComponent } from './atoms/icons/meepik-try-emotion-fr/meepik-try-emotion-fr.component';
import { MeepikReactingVoiceTextFrComponent } from './atoms/icons/meepik-reacting-voice-text-fr/meepik-reacting-voice-text-fr.component';
import { MeepikCarrouselComponent } from './molecules/meepik-carrousel/meepik-carrousel.component';

const routes: Routes = [
  { path: '', component: MeepikMainComponent },
  { path: 'home', component: MeepikMainComponent },
  { path: 'cgu', component: MeepikCguComponent },
  { path: 'legals', component: MeepikLegalsComponent },
  { path: 'privacy', component: MeepikPrivacyComponent },
  { path: 'shop', component: MeepikShopComponent },
  { path: 'faq', component: MeepikFaqComponent },
  { path: 'payment/success', component: MeepikPaymentSuccessComponent },
  { path: 'payment/cancel', component: MeepikPaymentCancelComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    MeepikLoginComponent,
    MeepikTitleComponent,
    MeepikBannerComponent,
    MeepikMeeComponent,
    MeepikLiveUserButtonComponent,
    MeepikBannerComponent,
    MeepikBlogCardComponent,
    MeepikMenuComponent,
    MeepikTextAreaComponent,
    MeepikBecomeAvatarComponent,
    MeepikCreateYourMeeComponent,
    MeepikInstallOnObsComponent,
    MeepikGoLiveComponent,
    MeepikLearnMoreVtuberComponent,
    MeepikLiveUsersComponent,
    MeepikButtonComponent,
    MeepikDownloadButtonComponent,
    MeepikUseMicButtonComponent,
    MeepikSeparatorComponent,
    MeepikWindowsIconComponent,
    MeepikMicIconComponent,
    MeepikLogoComponent,
    MeepikHomeIconComponent,
    MeepikShopIconComponent,
    MeepikDownloadIconComponent,
    MeepikEmotionalButtonComponent,
    MeepikAngryIconComponent,
    MeepikSmileIconComponent,
    MeepikDeathIconComponent,
    MeepikLoveIconComponent,
    MeepikMeeMascotteComponent,
    MeepikMeeMangaComponent,
    MeepikTryEmotionTextComponent,
    MeepikReactingVoiceTextComponent,
    MeepikArrowRightIconComponent,
    MeepikArrowLeftIconComponent,
    MeepikRigthArrowButtonComponent,
    MeepikReviewCardComponent,
    MeepikStarIconComponent,
    MeepikParallaxComponent,
    MeepikDiscordBannerComponent,
    MeepikDiscordButtonComponent,
    MeepikDiscordIconComponent,
    MeepikFooterComponent,
    MeepikCguComponent,
    MeepikMainComponent,
    MeepikShopComponent,
    MeepikFaqComponent,
    MeepikFaqQuestionComponent,
    MeepikPaymentSuccessComponent,
    MeepikPaymentCancelComponent,
    MeepikArrowComponent,
    MeepikPrivacyComponent,
    MeepikLegalsComponent,
    MeepikCryIconComponent,
    MeepikNotificationDrawerComponent,
    MeepikSwitchComponent,
    MeepikTryEmotionFrComponent,
    MeepikReactingVoiceTextFrComponent,
    MeepikCarrouselComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    AuthModule.forRoot({
      config: {
        authority: environment.authunUrl,
        redirectUrl: `${window.location.origin}/signin-oidc`,
        postLogoutRedirectUri: `${window.location.origin}/signout-callback-oidc`,
        clientId: 'web',
        responseType: 'code',
        scope: 'openid offline_access',
        silentRenew: true,
        useRefreshToken: true,
        startCheckSession: true,
        logLevel: LogLevel.Debug,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: AbstractSecurityStorage,
      useClass: DefaultLocalStorageService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}