import { Component, Input } from '@angular/core';
import { Mee } from 'src/app/global/mee/init-mee';
import { Emotion } from 'src/app/molecules/meepik-emotional-button/meepik-emotional-button.component';

@Component({
  selector: 'app-meepik-mee-manga',
  templateUrl: './meepik-mee-manga.component.html',
  styleUrls: ['./meepik-mee-manga.component.scss'],
})
export class MeepikMeeMangaComponent {
  @Input() meeName: string = 'manga';
  @Input() width: number = 369;
  @Input() height: number = 482;
  @Input() animation: Emotion = 'idle';
  Mee = new Mee(
    this.width,
    this.height,
    '../../../assets/mee/',
    this.meeName,
    `${this.meeName}.json`,
    `${this.meeName}.atlas`,
    this.animation,
    Date.now(),
    0.3,
    'card2'
  );
  spawn() {
    this.Mee.spawn();
  }
  ngOnInit() {
    this.Mee.init();
    this.spawn();
  }
  changeContainer(container: string) {
    this.Mee.changeContainer(container);
  }
  changeEmotion(emotion: string) {
    this.Mee.changeAnimation(emotion);
  }
}
