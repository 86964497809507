import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-install-on-obs',
  templateUrl: './meepik-install-on-obs.component.html',
  styleUrls: ['./meepik-install-on-obs.component.scss'],
})
export class MeepikInstallOnObsComponent {
  @Input() installOBSOpacity: number = 1;
  @Input() meeObsInstallPositionRight: string = '0px';
  viewHeight = window.visualViewport?.height;
  viewWidth = window.visualViewport?.width;
}
