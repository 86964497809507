<div class="container blur">
  <div class="titleArea">
    <div
      [ngStyle]="{
        display: 'flex',
        alignItems: 'baseline',
        paddingBottom: '9px'
      }"
    >
      <app-meepik-title
        [text]="'faq.title.left' | translate"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'faq.title.right' | translate"
        [textColor]="'#ffce57'"
        [isOutline]="true"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'.'"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
      />
    </div>
    <app-meepik-separator [width]="'30px'" />
    <app-meepik-text-area>
      <div [ngStyle]="{ paddingTop: '9px', paddingRight: '30px' }">
        {{ "faq.text.description" | translate }}
      </div>
    </app-meepik-text-area>
    <div class="questionArea">
      <div class="leftPart">
        @for (questionNumber of questions; track $index) {
        <app-meepik-faq-question
          [questionText]="getAnswer(questionNumber) | translate"
          [questionTitle]="getQuestionText(questionNumber) | translate"
        />
        }
      </div>
      <div class="rightPart">
        <img
          [ngStyle]="{
            position: 'absolute',
            bottom: 0,
            left: '-31px',
            transform: 'scale(-1, 1)'
          }"
          src="../../../assets/img/SHOP_MEE_MANGA.png"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
