<div>
  <app-meepik-button
    [height]="height"
    [width]="width"
    [props]="btnProps"
    [borderRadius]="'30px'"
  >
    <div
      [ngStyle]="{
        fontFamily: 'spot',
        display: 'flex',
        width: '100%'
      }"
    >
      <app-meepik-discord-icon
        [fillColor]="btnProps.textColor"
        [width]="'35px'"
        [height]="'35px'"
      />
      <div
        [ngStyle]="{
          height: '100%',
          marginLeft: '0.3rem',
          paddingLeft: '13px'
        }"
      >
        {{ "home.discordBanner.button" | translate }}
      </div>
    </div>
  </app-meepik-button>
</div>
