<div class="container">
  <div
    [ngStyle]="{
      display: 'flex',
      alignItems: 'center'
    }"
    (click)="prev()"
  >
    <app-meepik-rigth-arrow-button
      [isRight]="false"
      [height]="'56px'"
      [width]="'56px'"
    />
  </div>
  <div
    *ngFor="let component of components.slice(currentIndex, currentIndex + 2)"
    [@carouselAnimation]="'in'"
  >
    <ng-container *ngComponentOutlet="component"></ng-container>
  </div>
  <div
    [ngStyle]="{
      display: 'flex',
      alignItems: 'center'
    }"
    (click)="next()"
  >
    <app-meepik-rigth-arrow-button
      [isRight]="true"
      [height]="'56px'"
      [width]="'56px'"
    />
  </div>
</div>
