import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-meepik-button',
  templateUrl: './meepik-button.component.html',
  styleUrls: ['./meepik-button.component.scss'],
})
export class MeepikButtonComponent implements OnInit {
  constructor() {}
  @Input({ required: true }) width!: string;
  @Input({ required: true }) height!: string;
  @Input({ required: true }) props!: ButtonProps;
  @Input() borderRadius: string = '15px';
  @Input() activeAnimation: boolean = true;
  ngOnInit(): void {}
}

type ButtonProps = {
  backGroundColor: string;
  shadowColor: string;
  textColor: string;
  font: string;
  fontSize: string;
};
