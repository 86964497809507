import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-discord-icon',
  templateUrl: './meepik-discord-icon.component.svg',
  styleUrls: ['./meepik-discord-icon.component.scss'],
})
export class MeepikDiscordIconComponent {
  @Input() fillColor = 'white';
  @Input() width = '35px';
  @Input() height = '35px';
}
