import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-title',
  templateUrl: './meepik-title.component.html',
  styleUrls: ['./meepik-title.component.scss'],
})
export class MeepikTitleComponent {
  @Input({ required: true }) text!: string;
  @Input({ required: true }) textColor!: string;
  @Input({ required: false }) isOutline: boolean = false;
  @Input({ required: false }) fontSize: string = '15px';

  fontFamily = 'spot';

  ngOnInit(): void {
    this.fontFamily = this.isOutline ? 'spotOutline' : 'spot';
  }
}
