import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebUIService {
  private apiUrl = environment.webUIBack;
  private token = '';
  constructor(
    private http: HttpClient,
    private oidcSecurityService: OidcSecurityService
  ) {
    this.oidcSecurityService
      .getAccessToken()
      .subscribe((res) => (this.token = `Bearer ${res}`));
  }
  setToken() {
    this.oidcSecurityService
      .getAccessToken()
      .subscribe((res) => (this.token = `Bearer ${res}`));
  }
  getParams() {
    return this.http.get<WebuiSettings>(`${this.apiUrl}/settings`);
  }
  createSubscription(sessionId: string) {
    return this.http.post<CreateSubscriptionResponse>(
      `${this.apiUrl}/subscriptions`,
      { sessionId: sessionId },
      { headers: { Authorization: this.token } }
    );
  }
  cancelSubscription(subId: string) {
    return this.http.post(
      `${this.apiUrl}/subscriptions/cancel`,
      { subscriptionId: subId },
      { headers: { Authorization: this.token },
        responseType: 'text'  
      }
    );
  }
  keepSubscription(subId: string) {
    return this.http.post(
      `${this.apiUrl}/subscriptions/stay`,
      { subscriptionId: subId },
      { headers: { Authorization: this.token },
        responseType: 'text'  
      }
    );
  }
  getSessionURL() {
    return this.http.get<GetSubscriptioURLResponse>(
      `${this.apiUrl}/subscriptions/url`,
      { headers: { Authorization: this.token } }
    );
  }
  getCurrentSubscription() {
    return this.http.get<GetSubscriptionResponse>(
      `${this.apiUrl}/subscriptions`,
      { headers: { Authorization: this.token } }
    );
  }
}
interface GetSubscriptionResponse {
  subscriptionId: string;
  status: number;
}
interface GetSubscriptioURLResponse {
  subscriptionURL: string;
}
interface WebuiSettings {
  clientLink: string;
  authUrl: string;
}
interface CreateSubscriptionResponse {
  name: string;
  status: number;
  path: string;
  errors: [
    {
      message: string;
    }
  ];
}
