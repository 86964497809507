<app-meepik-button [height]="height" [width]="width" [props]="btnProps">
  <div
    [ngStyle]="{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between'
    }"
  >
    <app-meepik-mic-icon
      [fillColor]="btnProps.textColor"
      [width]="'32px'"
      [height]="'32px'"
    />
    <div [ngStyle]="{ height: '100%', marginLeft: '1rem' }">
      {{ "global.button.testMic" | translate }}
    </div>
  </div>
</app-meepik-button>
