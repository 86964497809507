import {
  AssetManager,
  AtlasAttachmentLoader,
  SkeletonJson,
  SkeletonMesh,
} from '@esotericsoftware/spine-threejs';
import * as THREE from 'three';
import { sleep } from '../script/sleep';
export class Mee {
  camera: THREE.PerspectiveCamera;
  assetManager: AssetManager;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  width: number;
  height: number;
  container: HTMLElement | null;
  skeletonFile: string;
  atlasFile: string;
  animation: string;
  baseUrl: string;
  meeName: string;
  lastFrameTime: number;
  canvas: HTMLCanvasElement;
  skeletonMesh: SkeletonMesh | undefined;
  mesh: THREE.Mesh;
  scale: number;
  canvaName: string;
  constructor(
    pWidth: number,
    pHeight: number,
    pBaseUrl: string,
    pMeeName: string,
    pSkeletonFile: string,
    pAtlasFile: string,
    pAnimation: string,
    pLastFrameTime: number,
    pScale: number,
    pCanvaName: string
  ) {
    this.camera = new THREE.PerspectiveCamera();
    this.assetManager = new AssetManager(pBaseUrl);
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.width = pWidth;
    this.height = pHeight;
    this.container = document.getElementById(pMeeName);
    this.atlasFile = pAtlasFile;
    this.skeletonFile = pSkeletonFile;
    this.animation = pAnimation;
    this.lastFrameTime = pLastFrameTime;
    this.baseUrl = pBaseUrl;
    this.container
      ? this.container.appendChild(this.renderer.domElement)
      : document.body.appendChild(this.renderer.domElement);
    this.canvas = this.renderer.domElement;
    this.meeName = pMeeName;
    this.mesh = new THREE.Mesh();
    this.scale = pScale;
    this.canvaName = pCanvaName;
  }

  init() {
    this.camera = new THREE.PerspectiveCamera(70, 1, 1, 3000);
    this.assetManager = new AssetManager(this.baseUrl);
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ alpha: true });
    this.camera.position.y = 340;
    this.camera.position.x = 20;
    this.camera.position.z = 486;

    this.renderer.setSize(this.width, this.height);
    this.container = document.getElementById(this.canvaName);
    this.container
      ? this.container.appendChild(this.renderer.domElement)
      : document.body.appendChild(this.renderer.domElement);
    this.canvas = this.renderer.domElement;
    this.assetManager.loadText(this.skeletonFile);
    this.assetManager.loadTextureAtlas(this.atlasFile);

    requestAnimationFrame(() => {
      this.load();
    });
  }
  changeContainer(containerName: string) {
    this.container = document.getElementById(containerName);
    this.container
      ? this.container.appendChild(this.renderer.domElement)
      : document.body.appendChild(this.renderer.domElement);
  }
  spawn() {
    if (this.skeletonMesh) {
      this.skeletonMesh.state.setAnimation(0, 'spawn', false);
      this.skeletonMesh.state.addAnimation(0, 'idle', true);
      this.mesh.add(this.skeletonMesh);
    }
    requestAnimationFrame(() => {
      this.render();
    });
  }
  changeAnimation(animation: string) {
    this.animation = animation;
    if (this.skeletonMesh) {
      this.skeletonMesh.state.setAnimation(0, animation, true);
      this.mesh.add(this.skeletonMesh);
    }

    requestAnimationFrame(() => {
      this.render();
    });
  }
  mouthAnimation = [
    'mouth_ch',
    'mouth_aa',
    'mouth_dd',
    'mouth_e',
    'mouth_ff',
    'mouth_i',
    'mouth_idle',
    'mouth_kk',
    'mouth_nn',
    'mouth_o',
    'mouth_pp',
    'mouth_rr',
    'mouth_ss',
    'mouth_th',
    'mouth_u',
  ];
  activateMouth() {
    if (this.skeletonMesh) {
      this.skeletonMesh.state.addAnimation(1, 'mouth_ss', false);
      sleep(100).then(() => {
        if (this.skeletonMesh) {
          this.skeletonMesh.state.addAnimation(1, 'mouth_aa', false);
        }
        sleep(100).then(() => {
          if (this.skeletonMesh) {
            this.skeletonMesh.state.addAnimation(1, 'mouth_u', false);
          }
          sleep(100).then(() => {
            if (this.skeletonMesh) {
              this.skeletonMesh.state.addAnimation(1, 'mouth_ss', false);
            }
            sleep(100).then(() => {
              if (this.skeletonMesh) {
                this.skeletonMesh.state.addAnimation(1, 'mouth_e', false);
              }
              sleep(100).then(() => {
                if (this.skeletonMesh) {
                  this.skeletonMesh.state.addAnimation(1, 'mouth_nn', false);
                }
                sleep(100).then(() => {
                  if (this.skeletonMesh) {
                    this.skeletonMesh.state.addAnimation(1, 'mouth_o', false);
                  }
                  sleep(100).then(() => {
                    if (this.skeletonMesh) {
                      this.skeletonMesh.state.addAnimation(
                        1,
                        'mouth_aa',
                        false
                      );
                    }
                    sleep(100).then(() => {
                      if (this.skeletonMesh) {
                        this.skeletonMesh.state.addAnimation(
                          1,
                          `mouth_idle${
                            this.animation !== 'idle' &&
                            this.animation !== 'spawn'
                              ? this.animation
                              : ''
                          }`,
                          false
                        );
                      }
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
  }

  load() {
    if (this.assetManager.isLoadingComplete()) {
      this.scene.add(this.mesh);
      let atlas = this.assetManager.require(this.atlasFile);

      let atlasLoader = new AtlasAttachmentLoader(atlas);

      let skeletonJson = new SkeletonJson(atlasLoader);

      skeletonJson.scale = this.scale;
      let skeletonData = skeletonJson.readSkeletonData(
        this.assetManager.require(this.skeletonFile)
      );
      this.skeletonMesh = new SkeletonMesh(skeletonData, (parameters) => {
        parameters.depthTest = true;
        parameters.depthWrite = true;
        parameters.alphaTest = 0.001;
      });
      this.skeletonMesh.state.setAnimation(0, 'spawn', false);
      this.skeletonMesh.state.addAnimation(0, 'idle', true);
      this.mesh.add(this.skeletonMesh);

      requestAnimationFrame(() => {
        this.render();
      });
    } else
      requestAnimationFrame(() => {
        this.load();
      });
  }
  render() {
    let now = Date.now() / 1000;
    let delta = now - this.lastFrameTime;
    this.lastFrameTime = now;

    this.resize();
    if (this.skeletonMesh) {
      this.skeletonMesh.update(delta);
    }

    this.renderer.render(this.scene, this.camera);

    requestAnimationFrame(() => {
      this.render();
    });
  }
  resize() {
    let w = this.width;
    let h = this.height;
    if (this.canvas.width != w || this.canvas.height != h) {
      this.canvas.width = w;
      this.canvas.height = h;
    }

    this.camera.aspect = w / h;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(w, h);
  }
}
