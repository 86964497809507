<div class="container">
  <div class="textCard">
    <app-meepik-text-area>
      <div class="text">
        <h1>Privacy Policies</h1>
        <p>
          At MEEPIK, accessible from https://meepik.com, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by MEEPIK and how we use it.
        </p>
        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to our Data Policies Officer at
          dpo&#64;meepik.com.
        </p>
        <i>
          Last modification: 04/01/2024
        </i>
        <h2>Log Files</h2>
        <p>
          MEEPIK follows a standard procedure of using log files. These files
          log visitors when they visit websites. The information collected by
          log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages. These are not linked to any information that is personally
          identifiable.
        </p>
        <p>
          These information are primarily used to analyse our traffic, in order
          to differentiate "users" and "robots" and manage our network bandwidth
        </p>
        <h2>Cookies</h2>
        <p>
          Like any other website, MEEPIK uses "cookies". These cookies are only
          used to store necessary authentication data.
        </p>
        <p>
          You will find on the list below all cookies that can be store on your
          browser from our website:
        </p>
        <ul>
          <li>
            <i>.AspNetCore.Identity.Application</i>
            <ul>
              <li>Life time: 1 month</li>
              <li>
                Usage: Allow authentication to be passed from browser to the
                Unity Client
              </li>
              <li>Type: Cookie</li>
            </ul>
          </li>
          <li>
            <i>meepik-token</i>
            <ul>
              <li>Life time: 1 hour</li>
              <li>Usage:</li>
              <li>Type: localstorage</li>
            </ul>
          </li>
          <li>
            <i>meepik-refresh-token</i>
            <ul>
              <li>Life time: 1 month</li>
              <li>Usage:</li>
              <li>Type: localstorage</li>
            </ul>
          </li>
        </ul>
        <h2>Children's Information</h2>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          MEEPIK does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
        <h2>Privacy Policy Only</h2>
        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in MEEPIK. This policy is not applicable to any
          information collected offline or via channels other than this website.
        </p>
        <h2>Consent</h2>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </div>
    </app-meepik-text-area>
  </div>
</div>
