import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-star-icon',
  templateUrl: './meepik-star-icon.component.svg',
  styleUrls: ['./meepik-star-icon.component.scss']
})
export class MeepikStarIconComponent {
  @Input() fillColor = 'yellow';
  @Input() width = '25px';
  @Input() height = '25px';
}
