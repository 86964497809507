import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-logo',
  templateUrl: './meepik-logo.component.svg',
  styleUrls: ['./meepik-logo.component.scss']
})
export class MeepikLogoComponent {
  @Input() fillColor = '#FFCE57';
  @Input() width = '78px';
  @Input() height = '23px';
}
