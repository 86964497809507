<div
  class="meepik_button"
  [ngClass]="activeAnimation ? 'meepik_button_active' : ''"
  [ngStyle]="{
    width: width,
    height: height,
    backgroundColor: props.backGroundColor,
    boxShadow: '0px 4px 0px ' + props.shadowColor,
    borderRadius: borderRadius
  }"
>
  <div
    class="meepik_button_text"
    [ngStyle]="{
      fontFamily: props.font,
      fontSize: props.fontSize,
      color: props.textColor
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
