import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-arrow',
  templateUrl: './meepik-arrow.component.svg',
  styleUrls: ['./meepik-arrow.component.scss']
})
export class MeepikArrowComponent {
  @Input() fillColor = 'white';
  @Input() width = '45px';
  @Input() height = '45px';
}
