import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-footer',
  templateUrl: './meepik-footer.component.html',
  styleUrls: ['./meepik-footer.component.scss']
})
export class MeepikFooterComponent {

}
