import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  isOpen = false;
  notificationText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed odio morbi quis commodo odio aenean. Morbi leo urna molestie at elementum eu facilisis. Enim nunc faucibus a pellentesque. Donec ac odio tempor orci dapibus. Quis auctor elit sed vulputate mi. Pellentesque nec nam aliquam sem et tortor consequat id. Magnis dis parturient montes nascetur ridiculus mus mauris vitae. Molestie at elementum eu facilisis sed odio. Ornare lectus sit amet est placerat. Est lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque. Bibendum enim facilisis gravida neque convallis a. Mauris commodo quis imperdiet massa tincidunt nunc. Tortor posuere ac ut consequat semper. Neque ornare aenean euismod elementum nisi quis eleifend quam. Lectus mauris ultrices eros in cursus turpis massa. Ullamcorper eget nulla facilisi etiam dignissim diam quis. Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at augue eget. Tristique nulla aliquet enim tortor.';
  constructor() {}
}
