import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-death-icon',
  templateUrl: './meepik-death-icon.component.svg',
  styleUrls: ['./meepik-death-icon.component.scss'],
})
export class MeepikDeathIconComponent {
  @Input() fillColor = 'white';
  @Input() width = '19px';
  @Input() height = '19px';
}
