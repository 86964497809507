import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-home-icon',
  templateUrl: './meepik-home-icon.component.svg',
  styleUrls: ['./meepik-home-icon.component.scss']
})
export class MeepikHomeIconComponent {
  @Input() fillColor = '#FFCE57';
  @Input() width = '78px';
  @Input() height = '23px';
}
