import { Component } from '@angular/core';
import { NotificationService } from 'src/api/helper/notificationManagement';

@Component({
  selector: 'app-meepik-notification-drawer',
  templateUrl: './meepik-notification-drawer.component.html',
  styleUrls: ['./meepik-notification-drawer.component.scss'],
})
export class MeepikNotificationDrawerComponent {
  constructor(public notificationService: NotificationService) {}
}
