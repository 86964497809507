export function addParameters(pUrl: string, param: URlParam[]) {
  const url = new URL(pUrl);
  param.forEach((param) => {
    url.searchParams.append(param.name, param.value);
  });
  return url;
}
export type URlParam = {
  name: string;
  value: string;
};
