import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-discord-banner',
  templateUrl: './meepik-discord-banner.component.html',
  styleUrls: ['./meepik-discord-banner.component.scss']
})
export class MeepikDiscordBannerComponent {

}
