import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CarouselService {
  private currentIndex = 0;

  setIndex(index: number): void {
    this.currentIndex = index;
  }

  getNextIndex(): number {
    return this.currentIndex + 2;
  }

  getPrevIndex(): number {
    return Math.max(0, this.currentIndex - 2);
  }
}
