<div class="cardContainer">
  <div
    [ngStyle]="{
      width: '100%',
      padding: '5px',
      paddingLeft: '15px',
      display: 'flex',
      paddingTop: '10px'
    }"
  >
    <img
      [src]="imgSrc"
      [ngStyle]="{
        width: '50px',
        height: '50px',
        borderRadius: '50%'
      }"
    />
    <div
      class="stars"
      [ngStyle]="{ display: 'flex', alignItems: 'center', paddingLeft: '15px' }"
    >
      <app-meepik-star-icon
        [fillColor]="'#0E875C'"
        [ngStyle]="{ paddingLeft: '5px' }"
      ></app-meepik-star-icon>
      <app-meepik-star-icon
        [fillColor]="'#0E875C'"
        [ngStyle]="{ paddingLeft: '5px' }"
      ></app-meepik-star-icon>
      <app-meepik-star-icon
        [fillColor]="'#0E875C'"
        [ngStyle]="{ paddingLeft: '5px' }"
      ></app-meepik-star-icon>
    </div>
  </div>
  <div [ngStyle]="{ width: '100%', padding: '5px', paddingLeft: '15px' }">
    <b>{{ cardProps.name }}</b> a évalué <b>Meepik</b>
  </div>
  <div [ngStyle]="{ width: '100%', padding: '5px', paddingLeft: '15px' }">
    {{ cardProps.text }}
  </div>
</div>
