<div id="goLive" [ngStyle]="{ height: '100%', width: '100%' }">
  <div
    [ngStyle]="{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '64px',
      opacity: goLiveOpacity,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
    }"
  >
    <div>
      <div [ngStyle]="{ display: 'flex' }">
        <div
          [ngStyle]="{
            display: 'flex',
            alignItems: 'baseline'
          }"
        >
          <app-meepik-title
            [text]="'home.parallaxBlock.goLiveBlock.title.left' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'home.parallaxBlock.goLiveBlock.title.right' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="true"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'.'"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
          />
        </div>
      </div>
      <app-meepik-separator [width]="'4%'" />
      <div [ngStyle]="{ display: 'flex' }">
        <app-meepik-text-area>
          <div [ngStyle]="{ paddingTop: '9px' }">
            {{
              "home.parallaxBlock.goLiveBlock.text.description_1" | translate
            }}
            <br />
            {{
              "home.parallaxBlock.goLiveBlock.text.description_2" | translate
            }}
          </div>
        </app-meepik-text-area>
      </div>
    </div>
    <video
      #video
      autoplay
      loop
      muted
      preload="auto"
      playsinline
      class="video"
      (canplay)="video.play()"
      (loadedmetadata)="video.muted = true"
    >
      <source
        src="https://files.meepik.com/meepik-storage-bucket/go-live-with-your-mee-01.mp4"
        type="video/mp4"
      />
    </video>
  </div>
  <div
    [ngStyle]="{
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      bottom: '53px'
    }"
  >
    <a
      [href]="getDownloadLink()"
      [ngStyle]="{
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 100
      }"
      target="_blank"
    >
      <app-meepik-download-button
        [width]="'450px'"
        [height]="'50px'"
        [type]="'Secondary'"
      />
    </a>
  </div>
  <div
    [ngStyle]="{
      position: 'absolute',
      right: 0,
      bottom: -10,
      opacity: goLiveOpacity
    }"
  >
    <img
      src="../../../assets/img/PARALLAX_BLUR_RIGHT.png"
      alt=""
      [ngStyle]="{ position: 'relative', right: 0, bottom: 0 }"
    />
  </div>
  <div
    [ngStyle]="{
      position: 'absolute',
      bottom: meeGoLivePositionBottom,
      left: 0,
      transform: 'scale(-1,1)'
    }"
  >
    <img src="../../../assets/img/VALYARIS_MEE.png" alt="" />
  </div>
</div>
