import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-download-button',
  templateUrl: './meepik-download-button.component.html',
  styleUrls: ['./meepik-download-button.component.scss'],
})
export class MeepikDownloadButtonComponent {
  @Input({ required: true }) width!: string;
  @Input({ required: true }) height!: string;
  @Input({ required: true }) type!: DownloadButtonType;
  @Input() borderRadius: string = '15px';
  btnPrimaryProps = {
    backGroundColor: '#431f73',
    shadowColor: '#5b2f89',
    textColor: '#ffffff',
    font: 'montserratRegular',
    fontSize: '15px',
  };
  btnSecondaryProps = {
    backGroundColor: '#ffce57',
    shadowColor: '#805b5d',
    textColor: '#654e15',
    font: 'montserratRegular',
    fontSize: '15px',
  };
  btnProps = {
    backGroundColor: '#431f73',
    shadowColor: '#5b2f89',
    textColor: '#ffffff',
    font: 'montserratRegular',
    fontSize: '15px',
  };
  ngOnInit(): void {
    this.btnProps =
      this.type == 'Primary' ? this.btnPrimaryProps : this.btnSecondaryProps;
  }
}
type DownloadButtonType = 'Primary' | 'Secondary';
