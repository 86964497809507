import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-payment-cancel',
  templateUrl: './meepik-payment-cancel.component.html',
  styleUrls: ['./meepik-payment-cancel.component.scss']
})
export class MeepikPaymentCancelComponent {

}
