<div
  [ngStyle]="{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }"
  (click)="clickUnfold()"
>
  <app-meepik-title
    [ngStyle]="{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      width: '600px'
    }"
    [text]="questionTitle"
    [textColor]="'#ffce57'"
    [isOutline]="false"
    [fontSize]="'25px'"
  />
  <div [ngClass]="getClass()">
    <app-meepik-arrow
      [width]="'75px'"
      [height]="'75px'"
      [fillColor]="'#ffce57'"
    />
  </div>
</div>
@if (isUnfold) {
<div
  class="faqInfo"
  [ngClass]="{ active: isUnfold }"
  [ngStyle]="{ opacity: isUnfold ? 1 : 0 }"
>
  <img
    src="../../../assets/img/SHOP_STAR.png"
    alt=""
    [ngStyle]="{ paddingRight: '20px' }"
  />
  {{ questionText }}
</div>
}
