import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-try-emotion-fr',
  templateUrl: './meepik-try-emotion-fr.component.svg',
  styleUrls: ['./meepik-try-emotion-fr.component.scss']
})
export class MeepikTryEmotionFrComponent {

}
