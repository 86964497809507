<div id="installObs">
  <div
    [ngStyle]="{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '64px',
      opacity: installOBSOpacity,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
    }"
  >
    <div>
      <div [ngStyle]="{ display: 'flex' }">
        <div
          [ngStyle]="{
            display: 'flex',
            alignItems: 'baseline'
          }"
        >
          <app-meepik-title
            [text]="'home.parallaxBlock.installBlock.title.left' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'home.parallaxBlock.installBlock.title.right' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="true"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'.'"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
          />
        </div>
      </div>
      <app-meepik-separator [width]="'4%'" />
      <div [ngStyle]="{ display: 'flex' }">
        <app-meepik-text-area>
          <div [ngStyle]="{ paddingTop: '9px' }">
            {{ "home.parallaxBlock.installBlock.text.description" | translate }}
          </div>
        </app-meepik-text-area>
      </div>
    </div>
    <video
      #video
      autoplay
      loop
      muted
      playsinline
      preload="auto"
      class="video"
      (canplay)="video.play()"
      (loadedmetadata)="video.muted = true"
    >
      <source
        src="https://files.meepik.com/meepik-storage-bucket/easy-setup-01.mp4"
        type="video/mp4"
      />
    </video>
  </div>
  <div
    [ngStyle]="{
      position: 'absolute',
      left: 0,
      bottom: -10,
      opacity: installOBSOpacity
    }"
  >
    <img
      src="../../../assets/img/PARALLAX_BLUR_LEFT.png"
      alt=""
      [ngStyle]="{ position: 'relative', left: 0, bottom: 0 }"
    />
  </div>
  <div
    [ngStyle]="{
      position: 'absolute',
      top: 0,
      right: meeObsInstallPositionRight
    }"
  >
    <img src="../../../assets/img/MEE_OBS_INSTALL.png" alt="" />
  </div>
</div>
