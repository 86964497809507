import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticatedResult,
  LoginResponse,
  OidcSecurityService,
} from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeepikUserService {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {}
  isAuthenticated$: Observable<AuthenticatedResult> =
    this.oidcSecurityService.isAuthenticated$;

  checkSessionChanged() {
    this.oidcSecurityService.checkSessionChanged$.subscribe((hasChanged) => {
      if (hasChanged) this.oidcSecurityService.logoffLocal();
    });
  }

  refreshSession() {
    this.oidcSecurityService.forceRefreshSession().subscribe();
  }

  login() {
    this.oidcSecurityService.authorize();
  }
  logout() {
    this.oidcSecurityService.logoffLocal();
  }
  checkAuth() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {});
  }
}
