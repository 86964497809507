<div class="container">
  <div class="card" [style.background-image]="'url(' + card.imgUrl + ')'"></div>
  <div class="title">
    <app-meepik-title
      [text]="card.title"
      [textColor]="'#ffffff'"
      [isOutline]="false"
      [fontSize]="'24px'"
    />
  </div>
  <div class="author">
    <app-meepik-text-area>
      by {{ card.author }}, {{ card.date }}
    </app-meepik-text-area>
  </div>
</div>
