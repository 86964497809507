<div class="container">
  <app-meepik-button [height]="height" [width]="width" [props]="btnProps">
    @switch (emotion) { @case ("angry") {
    <app-meepik-angry-icon
      [fillColor]="btnProps.textColor"
      [width]="'24px'"
      [height]="'24px'"
    />
    } @case("haha") {
    <app-meepik-smile-icon
      [fillColor]="btnProps.textColor"
      [width]="'24px'"
      [height]="'24px'"
    />
    } @case("fear") {
    <app-meepik-death-icon
      [fillColor]="btnProps.textColor"
      [width]="'24px'"
      [height]="'24px'"
    />
    } @case("love"){
    <app-meepik-love-icon
      [fillColor]="btnProps.textColor"
      [width]="'24px'"
      [height]="'24px'"
    />
    }@case("cry"){
    <app-meepik-cry-icon
      [fillColor]="btnProps.textColor"
      [width]="'24px'"
      [height]="'24px'"
    />} }
  </app-meepik-button>
</div>
