import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-meepik-review-card',
  templateUrl: './meepik-review-card.component.html',
  styleUrls: ['./meepik-review-card.component.scss'],
})
export class MeepikReviewCardComponent {
  @Input() cardProps: Card = {
    icon: 'default',
    name: 'Meepik',
    stars: 5,
    text: 'Super app UwU !',
  };

  public imgSrc = `../../../assets/img/${this.cardProps.icon}.png`;
}
export type Card = {
  icon: string;
  name: string;
  stars: number;
  text: string;
};
