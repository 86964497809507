<div class="container">
  <div class="left">
    <div class="leftBlur">
      <img src="assets/img/BECOME_AVATAR_BLUR_LEFT.png" alt="" />
    </div>
    <div class="cards">
      <div [ngStyle]="{ width: '50vw' }">
        <div class="card card3" id="card3">
          <app-meepik-mee-mascotte />
        </div>
        <div class="card card2" id="card2">
          <app-meepik-mee-manga />
        </div>
        <div class="card card1" id="card1">
          <div class="reactingVoicePng">
            @if (translate.currentLang === 'fr') {
            <app-meepik-reacting-voice-text-fr />
            }@else {
            <app-meepik-reacting-voice-text />
            }
          </div>
          <app-meepik-mee />
          <div class="arrowRight">
            <app-meepik-rigth-arrow-button
              (click)="clickLeft()"
              [isRight]="true"
              [height]="'43px'"
              [width]="'43px'"
            />
          </div>
          <div class="arrowLeft">
            <app-meepik-rigth-arrow-button
              (click)="clickRight()"
              [isRight]="false"
              [height]="'43px'"
              [width]="'43px'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right">
    <div class="rightBlur">
      <img src="assets/img/BECOME_AVATAR_BLUR_RIGHT.png" alt="" />
    </div>
    <div class="textArea">
      <div
        [ngStyle]="{
          display: 'flex',
          alignItems: 'baseline',
        }"
      >
        <app-meepik-title
          [text]="'home.avatarBlock.title.left' | translate"
          [textColor]="'#ffce57'"
          [isOutline]="false"
          [fontSize]="'45px'"
          [ngStyle]="{ paddingRight: '0.3rem' }"
        />
        <app-meepik-title
          [text]="'home.avatarBlock.title.right' | translate"
          [textColor]="'#ffce57'"
          [isOutline]="true"
          [fontSize]="'45px'"
          [ngStyle]="{ paddingRight: '0.3rem' }"
        />
        <app-meepik-title
          [text]="'.'"
          [textColor]="'#ffce57'"
          [isOutline]="false"
          [fontSize]="'45px'"
        />
      </div>
      <div [ngStyle]="{ marginTop: '9px', marginBottom: '9px' }">
        <app-meepik-separator [width]="'30px'" />
      </div>
      <app-meepik-text-area [ngStyle]="{ width: '450px', display: 'block' }">
        {{ "home.avatarBlock.text.description" | translate }}
      </app-meepik-text-area>
    </div>
    <div class="btnArea">
      <div class="emotionalArea">
        <div class="emotionalButtonArea">
          <app-meepik-emotional-button
            [height]="'57px'"
            [width]="'57px'"
            [emotion]="'haha'"
            (click)="changeEmotion('haha')"
          />
          <app-meepik-emotional-button
            [height]="'57px'"
            [width]="'57px'"
            [emotion]="'love'"
            (click)="changeEmotion('love')"
          />
          <app-meepik-emotional-button
            [height]="'57px'"
            [width]="'57px'"
            [emotion]="'fear'"
            (click)="changeEmotion('fear')"
          />
          <app-meepik-emotional-button
            [height]="'57px'"
            [width]="'57px'"
            [emotion]="'angry'"
            (click)="changeEmotion('angry')"
          />
          <app-meepik-emotional-button
            [height]="'57px'"
            [width]="'57px'"
            [emotion]="'cry'"
            (click)="changeEmotion('cry')"
          />
          @if (translate.currentLang === 'fr') {
          <app-meepik-try-emotion-fr class="tryEmotionSvg" />
          }@else {
          <app-meepik-try-emotion-text class="tryEmotionSvg" />
          }
        </div>
      </div>
      <!-- <div (click)="startListening()" class="testMicBtn">
        <app-meepik-use-mic-button [width]="'412px'" [height]="'50px'" />
      </div>
      <div [ngStyle]="{ marginTop: '9px', marginBottom: '9px' }">
        <app-meepik-separator [width]="'18px'" />
      </div> -->
      <div class="downloadBtn" [ngStyle]="{ marginTop: '9px' }">
        <a
          [href]="getDownloadLink()"
          [ngStyle]="{
              textDecoration: 'none',
              width: '100%',
            }"
          target="_blank"
        >
          <app-meepik-download-button
            [width]="'412px'"
            [height]="'50px'"
            [type]="'Primary'"
          />
        </a>
      </div>
    </div>
  </div>
</div>
