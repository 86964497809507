<app-meepik-separator [width]="'100%'" />
<div class="banner">
  <img class="trustpilotImage" src="assets/img/TRUSTPILOT.png" alt="" />
  <div
    [ngStyle]="{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }"
  >
    <div [ngStyle]="{ display: 'flex', justifyContent: 'center' }">
      <div
        [ngStyle]="{
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: '27px',
      }"
      >
        <app-meepik-title
          [text]="'home.trustpilotBanner.title.left' | translate"
          [textColor]="'#0E875C'"
          [isOutline]="false"
          [fontSize]="'45px'"
          [ngStyle]="{ paddingRight: '0.3rem' }"
        />
        <app-meepik-title
          [text]="'home.trustpilotBanner.title.right' | translate"
          [textColor]="'#0E875C'"
          [isOutline]="true"
          [fontSize]="'45px'"
          [ngStyle]="{ paddingRight: '0.3rem' }"
        />
      </div>
    </div>
    <a
      href="https://fr.trustpilot.com/review/meepik.com"
      target="_blank"
      rel="noopener"
      [ngStyle]="{ textDecoration: 'none' }"
    >
      <app-meepik-button
        [height]="'71px'"
        [width]="'412px'"
        [props]="btnProps"
        [borderRadius]="'30px'"
      >
        <div
          [ngStyle]="{
            fontFamily: 'spot',
            display: 'flex',
            width: '100%'
          }"
        >
          <div
            [ngStyle]="{
            height: '100%',
          }"
          >
            {{ "home.trustpilotBanner.button" | translate }}
          </div>
        </div>
      </app-meepik-button>
    </a>
  </div>
</div>
<app-meepik-separator [width]="'100%'" />
