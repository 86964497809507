import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MeepikUserService } from 'src/api/helper/userService';
import { Status } from 'src/api/webui/subsciptionStatus';
import { WebUIService } from 'src/api/webui/webuiApi';
import Stripe from 'stripe';

@Component({
  selector: 'app-meepik-shop',
  templateUrl: './meepik-shop.component.html',
  styleUrls: ['./meepik-shop.component.scss'],
})
export class MeepikShopComponent implements OnInit {
  hasClicked: boolean = false;
  test: any;
  hasSubscribed: boolean = false;
  hasPendingCancel: boolean = false;
  subId: string = '';
  isAuthenticated: boolean = false;
  constructor(
    private webuiService: WebUIService,
    private userService: MeepikUserService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.userService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;
      if (isAuthenticated) this.checkSubscription();
    });
  }
  login() {
    this.userService.login();
  }
  checkSubscription() {
    this.webuiService.setToken();
    this.webuiService.getCurrentSubscription().subscribe((res) => {
      this.hasSubscribed = res.status === Status.Active;
      this.hasPendingCancel = res.status === Status.CancelledAtPeriodEnd;
      this.subId = res.subscriptionId;
      this.changeDetectorRef.detectChanges();
    });
  }
  clickPayment() {
    this.webuiService.setToken();
    this.webuiService.getSessionURL().subscribe((res) => {
      window.open(res?.subscriptionURL ?? '', '_self');
    });
  }
  clickCancel() {
    this.webuiService.setToken();
    if (this.subId !== '') {
      this.webuiService.cancelSubscription(this.subId).subscribe(() =>
        this.checkSubscription()
      );
    }
  }
  clickStaySubscribe() {
    this.webuiService.setToken();
    if (this.subId !== '') {
      this.webuiService.keepSubscription(this.subId).subscribe(() =>
        this.checkSubscription()
      );
    }
  }
}
