<div class="container">
  <app-meepik-become-avatar></app-meepik-become-avatar>
  <app-meepik-separator [width]="'100%'" />
  <div [ngStyle]="{ position: 'sticky' }">
    <app-meepik-parallax />
  </div>
  <app-meepik-separator [width]="'100%'" />

  <!-- <app-meepik-learn-more-vtuber /> -->
  <app-meepik-discord-banner />
  <app-meepik-live-users />
  <app-meepik-banner [cards]="cards" />
</div>
