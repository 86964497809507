import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AudioStreamService } from 'src/api/helper/audioStreamService';
import { GlobalParamService } from 'src/api/helper/getParams';
import { MeepikMeeMangaComponent } from 'src/app/atoms/meepik-mee-manga/meepik-mee-manga.component';
import { MeepikMeeMascotteComponent } from 'src/app/atoms/meepik-mee-mascotte/meepik-mee-mascotte.component';
import { MeepikMeeComponent } from 'src/app/atoms/meepik-mee/meepik-mee.component';
import { Emotion } from 'src/app/molecules/meepik-emotional-button/meepik-emotional-button.component';

@Component({
  selector: 'app-meepik-become-avatar',
  templateUrl: './meepik-become-avatar.component.html',
  styleUrls: ['./meepik-become-avatar.component.scss'],
})
export class MeepikBecomeAvatarComponent {
  currentSelection = 1;

  @ViewChild(MeepikMeeComponent)
  private emy!: MeepikMeeComponent;

  @ViewChild(MeepikMeeMangaComponent)
  private manga!: MeepikMeeMangaComponent;

  @ViewChild(MeepikMeeMascotteComponent)
  private mascotte!: MeepikMeeMascotteComponent;
  startListening() {
    this.getCurrentMee().Mee.activateMouth();
  }

  ngOnDestroy(): void {
    this.audioService.stopMicrophone();
  }
  emotion = 'idle';
  constructor(
    private globalParamService: GlobalParamService,
    private audioService: AudioStreamService,
    public translate: TranslateService
  ) {}
  getDownloadLink() {
    return this.globalParamService.clientLink;
  }
  getCurrentMee() {
    switch (this.currentSelection) {
      case 0:
        return this.manga;
      case 1:
        return this.emy;
      case 2:
        return this.mascotte;
      default:
        return this.emy;
    }
  }
  changeEmotion(newEmotion: Emotion) {
    if (newEmotion !== this.emotion) {
      switch (this.currentSelection) {
        case 0:
          this.getCurrentMee().changeEmotion(newEmotion);
          break;
        case 1:
          this.getCurrentMee().changeEmotion(newEmotion);
          break;
        case 2:
          this.getCurrentMee().changeEmotion(newEmotion);
          break;
        default:
          break;
      }

      this.emotion = newEmotion;
    }
  }
  changeMee() {
    switch (this.currentSelection) {
      case 0:
        this.emy.changeContainer('card3');
        this.getCurrentMee().changeContainer('card1');
        this.mascotte.changeContainer('card2');
        this.getCurrentMee().spawn();
        this.emy.changeEmotion('idle');
        this.mascotte.changeEmotion('idle');
        break;
      case 1:
        this.getCurrentMee().changeContainer('card1');
        this.manga.changeContainer('card2');
        this.mascotte.changeContainer('card3');
        this.getCurrentMee().spawn();
        this.manga.changeEmotion('idle');
        this.mascotte.changeEmotion('idle');
        break;
      case 2:
        this.emy.changeContainer('card2');
        this.manga.changeContainer('card3');
        this.getCurrentMee().changeContainer('card1');
        this.getCurrentMee().spawn();
        this.emy.changeEmotion('idle');
        this.manga.changeEmotion('idle');
        break;
      default:
        break;
    }
  }
  clickRight() {
    if (this.currentSelection + 1 > 2) {
      this.currentSelection = 0;
    } else {
      this.currentSelection += 1;
    }
    this.changeMee();
  }
  clickLeft() {
    if (this.currentSelection - 1 < 0) {
      this.currentSelection = 2;
    } else {
      this.currentSelection -= 1;
    }
    this.changeMee();
  }
}
