import { Component, Input } from '@angular/core';
import { NotificationService } from 'src/api/helper/notificationManagement';

@Component({
  selector: 'app-meepik-faq-question',
  templateUrl: './meepik-faq-question.component.html',
  styleUrls: ['./meepik-faq-question.component.scss'],
})
export class MeepikFaqQuestionComponent {
  @Input() questionTitle: string = '';
  @Input() questionText: string = '';
  isUnfold: boolean = false;
  constructor(private notificationService: NotificationService) {}
  clickUnfold() {
    this.isUnfold = !this.isUnfold;
  }
  getClass() {
    return `arrowButton ${this.isUnfold ? 'arrowButtonBottom' : ''}`;
  }
}
