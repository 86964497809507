import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-arrow-right-icon',
  templateUrl: './meepik-arrow-right-icon.component.svg',
  styleUrls: ['./meepik-arrow-right-icon.component.scss'],
})
export class MeepikArrowRightIconComponent {
  @Input() fillColor = 'white';
  @Input() width = '45px';
  @Input() height = '45px';
}
