import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-blog-card',
  templateUrl: './meepik-blog-card.component.html',
  styleUrls: ['./meepik-blog-card.component.scss'],
})
export class MeepikBlogCardComponent {
  @Input() card: BlogCard = {
    imgUrl: '../../../assets/img/vTuber.png',
    author: 'Ariat',
    date: '23/01/1998',
    title: 'avatars virtuels : nouvel eldorado',
  };
}
type BlogCard = {
  imgUrl: string;
  author: string;
  date: string;
  title: string;
};
