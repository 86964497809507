<app-meepik-button
  [height]="height"
  [width]="width"
  [props]="btnProps"
  [activeAnimation]="false"
>
  @if (isRight) {
  <app-meepik-arrow-right-icon
    [fillColor]="btnProps.textColor"
    [width]="height"
    [height]="height"
    (mouseover)="btnProps.textColor = '#FFCE57'"
    (mouseout)="btnProps.textColor = '#FFFFFF'"
  />
  }@else {
  <app-meepik-arrow-left-icon
    [fillColor]="btnProps.textColor"
    [width]="height"
    [height]="height"
    (mouseover)="btnProps.textColor = '#FFCE57'"
    (mouseout)="btnProps.textColor = '#FFFFFF'"
  />
  }
</app-meepik-button>
