<div class="container blur" [ngClass]="hasClicked ? 'containerBlur' : ''">
  <div class="titleArea">
    <div
      [ngStyle]="{
        display: 'flex',
        alignItems: 'baseline',
        paddingBottom: '9px'
      }"
    >
      <app-meepik-title
        [text]="'shop.header.title.left' | translate"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'shop.header.title.right' | translate"
        [textColor]="'#ffce57'"
        [isOutline]="true"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'.'"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
      />
    </div>
    <app-meepik-separator [width]="'30px'" />
    <app-meepik-text-area>
      <div [ngStyle]="{ paddingTop: '9px', paddingRight: '30px' }">
        {{ "shop.header.text.description" | translate }}
      </div>
    </app-meepik-text-area>
    <div class="offerArea">
      <div class="leftPart">
        <img
          [ngStyle]="{ position: 'absolute', bottom: 0, right: '-31px' }"
          src="../../../assets/img/SHOP_MEE_MANGA.png"
          alt=""
        />
      </div>
      <div class="rightPart">
        <div class="price">6,00 €</div>
        <app-meepik-title
          [text]="'shop.payment.title' | translate"
          [textColor]="'#ffce57'"
          [isOutline]="false"
          [fontSize]="'40px'"
        />
        <div class="offerInfos">
          <img
            src="../../../assets/img/SHOP_STAR.png"
            alt=""
            [ngStyle]="{ paddingRight: '20px' }"
          />
          {{ "shop.payment.text.offerAnimation" | translate }}
        </div>
        <div class="offerInfos">
          <img
            src="../../../assets/img/SHOP_PEN.png"
            alt=""
            [ngStyle]="{ paddingRight: '20px' }"
          />
          {{ "shop.payment.text.offerCustomize" | translate }}
        </div>
        <div class="offerInfos">
          <img
            src="../../../assets/img/SHOP_REC.png"
            alt=""
            [ngStyle]="{ paddingRight: '20px' }"
          />
          {{ "shop.payment.text.offerRecord" | translate }}
        </div>
        <app-meepik-separator
          width="150px"
          [ngStyle]="{ marginTop: '20px', marginBottom: '20px' }"
        />
        @if(!isAuthenticated){
          <app-meepik-button
            (click)="login()"
            [height]="'44px'"
            [width]="'309px'"
            [props]="{
                backGroundColor: '#ffce57',
                shadowColor: '#d27f1e',
                textColor: '#431f73',
                font: 'spot',
                fontSize: '20px',
              }"
          >
            <img
              src="../../../assets/img/SHOP_CART.png"
              alt=""
              [ngStyle]="{ paddingRight: '13px' }"
            />
            {{ "global.button.login" | translate }}
          </app-meepik-button>
          }@else if (hasPendingCancel){
          <app-meepik-button
            (click)="clickStaySubscribe()"
            [height]="'44px'"
            [width]="'309px'"
            [props]="{
                  backGroundColor: '#ffce57',
                  shadowColor: '#d27f1e',
                  textColor: '#431f73',
                  font: 'spot',
                  fontSize: '20px',
                }"
          >
            <img
              src="../../../assets/img/SHOP_CART.png"
              alt=""
              [ngStyle]="{ paddingRight: '13px' }"
            />
            {{ "shop.payment.button.staySubscribe" | translate }}
          </app-meepik-button>
          }@else if (!(hasSubscribed)) {
        <app-meepik-button
          (click)="clickPayment()"
          [height]="'44px'"
          [width]="'309px'"
          [props]="{
              backGroundColor: '#ffce57',
              shadowColor: '#d27f1e',
              textColor: '#431f73',
              font: 'spot',
              fontSize: '20px',
            }"
        >
          <img
            src="../../../assets/img/SHOP_CART.png"
            alt=""
            [ngStyle]="{ paddingRight: '13px' }"
          />
          {{ "shop.payment.button.subscribe" | translate }}
        </app-meepik-button>
        }@else if (hasSubscribed) {
        <app-meepik-button
          (click)="clickCancel()"
          [height]="'44px'"
          [width]="'309px'"
          [props]="{
              backGroundColor: '#ffce57',
              shadowColor: '#d27f1e',
              textColor: '#431f73',
              font: 'spot',
              fontSize: '20px',
            }"
        >
          <img
            src="../../../assets/img/SHOP_CART.png"
            alt=""
            [ngStyle]="{ paddingRight: '13px' }"
          />
          {{ "shop.payment.button.cancelSubscription" | translate }}
        </app-meepik-button>
        }
      </div>
    </div>
  </div>
</div>
<app-meepik-separator [width]="'100%'" />
<div class="container" [ngClass]="hasClicked ? 'containerBlur' : ''">
  <img
    src="../../../assets/img/SHOP_MORE_CUSTOMIZATION_BLUR.png"
    alt=""
    [ngStyle]="{ position: 'absolute', right: 0 }"
  />
  <div class="titleArea">
    <div
      [ngStyle]="{
        display: 'flex',
        alignItems: 'baseline',
        paddingBottom: '9px'
      }"
    >
      <app-meepik-title
        [text]="'shop.materialsShop.title.left' | translate"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'shop.materialsShop.title.right' | translate"
        [textColor]="'#ffce57'"
        [isOutline]="true"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'.'"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
      />
    </div>
    <app-meepik-separator [width]="'30px'" />
    <app-meepik-text-area>
      <div [ngStyle]="{ paddingTop: '9px', paddingRight: '30px' }">
        {{ "shop.materialsShop.text.description" | translate }}
      </div>
    </app-meepik-text-area>
  </div>
  <div class="commingSoon">
    <div class="commingSoonText">
      <app-meepik-title
        [text]="'shop.materialsShop.text.teaser' | translate"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'64px'"
      />
    </div>
    <div class="cards">
      <div class="card"></div>
      <div class="card"></div>
      <div class="card"></div>
      <img
        src="../../../assets/img/VALYARIS_MEE.png"
        alt=""
        [ngStyle]="{ position: 'absolute', bottom: 0, right: 0 }"
      />
    </div>
  </div>
</div>
