<div id="createYourMee" [ngStyle]="{ width: '100%' }">
  <div
    [ngStyle]="{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '64px',
      opacity: createYourMeeOpacity,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
    }"
  >
    <div>
      <div [ngStyle]="{ display: 'flex', width: '100%', }">
        <div
          [ngStyle]="{
          display: 'flex',
          alignItems: 'baseline',
          width: '100%',
        }"
        >
          <app-meepik-title
            [text]="'home.parallaxBlock.createBlock.title.left' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'home.parallaxBlock.createBlock.title.right' | translate"
            [textColor]="'#ffce57'"
            [isOutline]="true"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'.'"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
          />
        </div>
      </div>
      <app-meepik-separator [width]="'4%'" />
      <div [ngStyle]="{ display: 'flex' }">
        <app-meepik-text-area>
          <div [ngStyle]="{ paddingTop: '9px' }">
            {{ "home.parallaxBlock.createBlock.text.description" | translate }}
          </div>
        </app-meepik-text-area>
      </div>
    </div>
    <video
      #video
      preload="auto"
      playsinline
      autoplay
      muted
      loop
      class="video"
      (canplay)="video.play()"
      (loadedmetadata)="video.muted = true"
    >
      <source
        src="https://files.meepik.com/meepik-storage-bucket/mee-building-01.mp4"
        type="video/mp4"
      />
    </video>
  </div>
  <div
    [ngStyle]="{
      position: 'absolute',
      right: 0,
      bottom: -10,
      opacity: createYourMeeOpacity
    }"
  >
    <img
      src="../../../assets/img/PARALLAX_BLUR_RIGHT.png"
      alt=""
      [ngStyle]="{ position: 'relative', right: 0, bottom: 0 }"
    />
  </div>
  <div
    [ngStyle]="{
      position: 'absolute',
      left: thumbUpPositionLeft,
      bottom: 0,
    }"
  >
    <img src="../../../assets/img/MEE_THUMB_UP.png" alt="" />
  </div>
</div>
