import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-reacting-voice-text-fr',
  templateUrl: './meepik-reacting-voice-text-fr.component.svg',
  styleUrls: ['./meepik-reacting-voice-text-fr.component.scss']
})
export class MeepikReactingVoiceTextFrComponent {

}
