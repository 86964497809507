import { Component, Input } from '@angular/core';
import { GlobalParamService } from 'src/api/helper/getParams';

@Component({
  selector: 'app-meepik-go-live',
  templateUrl: './meepik-go-live.component.html',
  styleUrls: ['./meepik-go-live.component.scss'],
})
export class MeepikGoLiveComponent {
  @Input() goLiveOpacity: number = 1;
  @Input() meeGoLivePositionBottom: string = '0px';
  viewHeight = window.visualViewport?.height;
  constructor(private globalParamService: GlobalParamService) {}
  getDownloadLink() {
    return this.globalParamService.clientLink;
  }
}
