import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-main',
  templateUrl: './meepik-main.component.html',
  styleUrls: ['./meepik-main.component.scss'],
})
export class MeepikMainComponent {
  cards = [
    {
      icon: 'default',
      name: 'Meepik',
      stars: 5,
      text: 'Super app UwU !',
    },
    {
      icon: 'default',
      name: 'Meepik',
      stars: 5,
      text: 'Super app UwU !',
    },
    {
      icon: 'default',
      name: 'Meepik',
      stars: 5,
      text: 'Super app UwU !',
    },
  ];
}
