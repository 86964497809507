import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-privacy',
  templateUrl: './meepik-privacy.component.html',
  styleUrls: ['./meepik-privacy.component.scss']
})
export class MeepikPrivacyComponent {

}
