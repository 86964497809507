<app-meepik-button
  [height]="height"
  [width]="width"
  [props]="btnProps"
  [borderRadius]="borderRadius"
>
  <div
    [ngStyle]="{
      fontFamily: 'montserratRegular',
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    }"
  >
    <app-meepik-windows-icon [fillColor]="btnProps.textColor" />
    <div [ngStyle]="{ height: '100%', marginLeft: '0.3rem' }">
      {{ "global.button.downloadLeftText" | translate }}
    </div>
    <div
      [ngStyle]="{ fontWeight: 'bold', height: '100%', marginLeft: '0.3rem' }"
    >
      Meepik
    </div>
    <div [ngStyle]="{ height: '100%', marginLeft: '0.3rem' }">
      {{ "global.button.downloadRightText" | translate }}
    </div>
  </div>
</app-meepik-button>
