import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioStreamService {
  private audioContext: AudioContext;
  private microphoneStream!: MediaStream;
  private analyser!: AnalyserNode;
  private soundThreshold = 150; // Adjust threshold as needed

  constructor() {
    this.audioContext = new AudioContext();
  }

  async startMicrophone(): Promise<void> {
    try {
      this.microphoneStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const microphoneSource = this.audioContext.createMediaStreamSource(
        this.microphoneStream
      );
      this.analyser = this.audioContext.createAnalyser();
      microphoneSource.connect(this.analyser);

      this.monitorSound();
    } catch (err) {
      console.error('Error accessing microphone:', err);
    }
  }

  monitorSound(): void {
    const dataArray = new Uint8Array(this.analyser.fftSize);

    const detectSound = () => {
      requestAnimationFrame(detectSound);
      this.analyser.getByteTimeDomainData(dataArray);

      const isSoundDetected = this.detectSound(dataArray);

      if (isSoundDetected) {
        console.log('Sound detected!');
        // Perform actions when sound is detected
      }
    };

    detectSound();
  }

  detectSound(dataArray: Uint8Array): boolean {
    const average =
      dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
    return average > this.soundThreshold;
  }

   stopMicrophone(): void {
    if (this.microphoneStream) {
      this.microphoneStream.getTracks().forEach((track) => track.stop());
    }
  }
}
