import { Component, Input } from '@angular/core';
import { Card } from 'src/app/molecules/meepik-review-card/meepik-review-card.component';

@Component({
  selector: 'app-meepik-banner',
  templateUrl: './meepik-banner.component.html',
  styleUrls: ['./meepik-banner.component.scss'],
})
export class MeepikBannerComponent {
  @Input() cards: Card[] = [];
  btnProps = {
    backGroundColor: '#FFFFFF',
    shadowColor: 'rgb(14, 135, 92,0.75)',
    textColor: '#0E875C',
    font: 'Spot',
    fontSize: '32px',
  };
}
