<div id="learnMoreABoutVTubbers" class="container">
  <div
    [ngStyle]="{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '82px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }"
  >
    <div [ngStyle]="{ width: '960px' }">
      <div [ngStyle]="{ display: 'flex' }">
        <div
          [ngStyle]="{
            display: 'flex',
            alignItems: 'baseline'
          }"
        >
          <app-meepik-title
            [text]="'LEARN MORE ABOUT'"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'VTUBBERS SPACE'"
            [textColor]="'#ffce57'"
            [isOutline]="true"
            [fontSize]="'45px'"
            [ngStyle]="{ paddingRight: '0.3rem' }"
          />
          <app-meepik-title
            [text]="'.'"
            [textColor]="'#ffce57'"
            [isOutline]="false"
            [fontSize]="'45px'"
          />
        </div>
      </div>
      <app-meepik-separator [width]="'4%'" />
      <div [ngStyle]="{ display: 'flex' }">
        <app-meepik-text-area>
          <div [ngStyle]="{ paddingTop: '9px' }">
            Read more about Vtubing and streaming on our blog !
          </div>
        </app-meepik-text-area>
      </div>
    </div>

    <div
      [ngStyle]="{
        display: 'flex',
        justifyContent: 'space-around',
        width: '1094px'
      }"
    >
      <app-meepik-carrousel />
    </div>
  </div>
</div>
