import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-meepik-parallax',
  templateUrl: './meepik-parallax.component.html',
  styleUrls: ['./meepik-parallax.component.scss'],
})
export class MeepikParallaxComponent {
  createYourMeeOpacity = 1;
  installOBSOpacity = 0;
  goLiveOpacity = 0;
  thumbUpPositionLeft = '0px';
  meeObsInstallPositionRight = '-400px';
  meeGoLivePositionBottom = '-825px';

  getScrollRatioPos(
    ratio: number,
    distanceScrolled: number,
    trigger: number
  ): number {
    var number = distanceScrolled * (-ratio / 800) + (trigger * ratio) / 800;
    var rounded = Math.round(number * 10) / 10;
    return rounded;
  }
  getScrollRatioPosRevert(
    ratio: number,
    distanceScrolled: number,
    trigger: number
  ): number {
    var number = distanceScrolled * (ratio / 800) + (trigger * ratio) / 800;
    var rounded = Math.round(number * 10) / 10;
    return rounded;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    var distanceScrolled = document.documentElement.scrollTop;
    if (distanceScrolled > 600 && distanceScrolled < 1400) {
      this.createYourMeeOpacity = this.getScrollRatioPos(
        1,
        distanceScrolled,
        1400
      );
      this.thumbUpPositionLeft = `${this.getScrollRatioPos(
        430,
        distanceScrolled,
        600
      )}px`;
    }
    if (distanceScrolled > 1400 && distanceScrolled < 2200) {
      this.meeObsInstallPositionRight = `${this.getScrollRatioPosRevert(
        400,
        distanceScrolled,
        -2200
      )}px`;
      this.installOBSOpacity = this.getScrollRatioPosRevert(
        1,
        distanceScrolled,
        -1400
      );
    }
    if (distanceScrolled > 2600 && distanceScrolled < 3400) {
      this.meeObsInstallPositionRight = `${this.getScrollRatioPos(
        400,
        distanceScrolled,
        2600
      )}px`;
      this.installOBSOpacity = this.getScrollRatioPos(
        1,
        distanceScrolled,
        3400
      );
      this.goLiveOpacity = this.getScrollRatioPosRevert(
        1,
        distanceScrolled,
        -2600
      );
      this.meeGoLivePositionBottom = `${this.getScrollRatioPosRevert(
        1000,
        distanceScrolled,
        -3400
      )}px`;
    }
    if (distanceScrolled > 3400) {
      this.meeGoLivePositionBottom = '0px';
      this.thumbUpPositionLeft = '-430px';
      this.meeObsInstallPositionRight = '-400px';
      this.createYourMeeOpacity = 0;
      this.goLiveOpacity = 1;
      this.installOBSOpacity = 0;
    }
    if (distanceScrolled < 600) {
      this.meeGoLivePositionBottom = '-1000px';
      this.thumbUpPositionLeft = '0';
      this.meeObsInstallPositionRight = '-400px';
      this.createYourMeeOpacity = 1;
      this.goLiveOpacity = 0;
      this.installOBSOpacity = 0;
    }
  }
}
