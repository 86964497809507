import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { URlParam, addParameters } from '../helper/addParameters';
import { addHeaders } from '../helper/addHeader';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TwitchApiService {
  private baseUrl: string = environment.twitchApiUrl;
  authorization = '';
  constructor(private http: HttpClient) {}
  getAuthToken(): Observable<GetAuthTokenResponse> {
    let url = environment.twitchAuthApiUrl;
    return this.http.post<GetAuthTokenResponse>(url, {
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      grant_type: 'client_credentials',
    });
  }
  getTwitchUserInfo(): Observable<GetTwitchUserInfoResponse> {
    const headers = {
      Authorization: `Bearer ${this.authorization}`,
      'Client-ID': environment.clientId,
    };
    let url = addParameters(
      `${this.baseUrl}users`,
      environment.meepikUSers.map((user) => {
        return { name: 'login', value: user } as URlParam;
      })
    );
    return this.http.get<GetTwitchUserInfoResponse>(
      url.toString(),
      addHeaders(headers)
    );
  }
  getTwitchUserOnlineInfo(): Observable<GetTwitchUserOnlineInfoResponse> {
    const headers = {
      Authorization: `Bearer ${this.authorization}`,
      'Client-ID': environment.clientId,
    };
    let url = addParameters(
      `${this.baseUrl}streams`,
      environment.meepikUSers.map((user) => {
        return { name: 'user_login', value: user } as URlParam;
      })
    );
    return this.http.get<GetTwitchUserOnlineInfoResponse>(
      url.toString(),
      addHeaders(headers)
    );
  }
}
export type GetTwitchUserInfoResponse = {
  data: TwitchUserInfo[];
};
export type GetTwitchUserOnlineInfoResponse = {
  data: TwitchUserOnlineInfo[];
};
export type GetAuthTokenResponse = {
  access_token: string;
  expires_in: number;
  token_type: string;
};
export type TwitchUserInfo = {
  id: string;
  login: string;
  display_name: string;
  type: string;
  broadcaster_type: string;
  description: string;
  profile_image_url: string;
  offline_image_url: string;
  view_count: number;
  created_at: string;
};
export type TwitchUserOnlineInfo = {
  id: string;
  user_id: string;
  user_login: string;
  user_name: string;
  game_id: string;
  game_name: string;
  type: string;
  title: string;
  viewer_count: number;
  started_at: string;
  language: string;
  thumbnail_url: string;
  tag_ids: [];
  tags: string[];
  is_mature: boolean;
};
