<a [href]="cardInfo.twitchlink" style="text-decoration: none" target="_blank">
  <div class="container">
    <div class="name">{{ cardInfo.name }}</div>
    <div
      class="img"
      [style.background-image]="'url(' + cardInfo.img + ')'"
    ></div>
    @if (cardInfo.isOnline) {
    <div class="button online">
      <div class="circle green"></div>
      {{ "home.liveUserBlock.button.live" | translate }}
    </div>
    }@else {
    <div class="button offline">
      <div class="circle red"></div>
      {{ "home.liveUserBlock.button.notLive" | translate }}
    </div>
    }
  </div>
</a>
