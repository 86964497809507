<div class="container">
  <div class="textCard">
    <app-meepik-text-area>
      <div class="text">
        <h1>GENERAL POLICIES :</h1>
        <p>
          These general policies of use (referred to as "Policies") aim to
          provide legal framework for the terms of availability of the site and
          services by MEEPIK and define the conditions of access and use of the
          services by the "User."
        </p>
        <p>
          These Policies can be accessed on the site under the "Policies"
          section.
        </p>
        <p>
          Any registration or use of the site implies the unconditional
          acceptance of these Policies by the user. Upon registration on the
          site via the Registration Form, each user expressly accepts these
          Policies by checking the box preceding the following text: "I
          acknowledge that I have read and understood the Policies, and I accept
          them."
        </p>
        <p>
          In case of non-acceptance of the Policies stipulated in this contract,
          the User must refrain from accessing the services offered by the site.
          meepik.com reserves the right to unilaterally modify the content of
          these Policies at any time.
        </p>
        <i> Last modification: 27/11/2023 </i>
        <h2>Article 1: Access to the Site</h2>
        <p>
          Access to the site meepik.com is free and allows access to the
          following services:
        </p>
        <ul>
          <li>Registration on the site</li>
          <li>Downloading the MEEPIK application</li>
          <li>Blog</li>
        </ul>
        <p>
          The site is accessible for free from anywhere and by any user with
          Internet access. All costs necessary to access the services (computer
          equipment, internet connection) are the responsibility of the user.
        </p>
        <p>
          Access to services dedicated to members is done using an identifier
          (Email) and a password.
        </p>
        <p>
          For maintenance or other reasons, access to the site may be
          interrupted or suspended without notice or justification.
        </p>
        <h2>Article 2: Intellectual Property</h2>
        <p>
          MEEPIK's trademarks and logos, as well as the content of the site
          meepik.com, including:
        </p>
        <ul>
          <li>Illustrations</li>
          <li>Graphics</li>
          <li>Texts</li>
        </ul>
        <p>
          are protected by the Intellectual Property Code and copyright.
          Reproduction and copying of content by the User require prior
          authorization from the site. In this case, any use for commercial
          purposes or for advertising purposes is prohibited.
        </p>
        <h2>Article 3: Liability</h2>
        <p>
          The sources of information disseminated on the site meepik.com are
          deemed reliable, but the site does not guarantee that it is free from
          defects, errors, or omissions.
        </p>
        <p>
          The information provided is for indicative and general purposes only
          and is not contractual
        </p>
        <p>
          The User ensures to keep his password secret. Any disclosure of the
          password, in any form, is prohibited. He assumes the risks associated
          with the use of his username and password. The site disclaims all
          responsibility.
        </p>
        <p>
          The site meepik.com cannot be held responsible for any viruses that
          could infect the user's computer or any computer equipment following
          the use, access, or download not originating from the official source
          (https://meepik.com).
        </p>
        <p>
          The site's responsibility cannot be engaged in the event of force
          majeure or the unforeseeable and insurmountable act of a third party.
        </p>
        <h2>Article 4: Hyperlinks</h2>
        <p>
          Hyperlinks may be present on the site. The User is informed that by
          clicking on these links, he will leave the site meepik.com. The latter
          has no control over the web pages to which these links lead and
          cannot, in any case, be responsible for their content.
        </p>
        <h2>Article 5: Applicable Law and Jurisdiction</h2>
        <p>
          French law applies to this contract. In the absence of an amicable
          resolution of a dispute arising between the parties, the French courts
          will have sole jurisdiction to hear it. For any questions regarding
          the application of these Policies, you can contact the editor at the
          contact information provided in the legal notices.
        </p>
      </div>
    </app-meepik-text-area>
  </div>
</div>
