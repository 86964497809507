import { Injectable } from '@angular/core';
import { WebUIService } from '../webui/webuiApi';

@Injectable({
  providedIn: 'root',
})
export class GlobalParamService {
  clientLink: string = '';
  authUrl: string = '';
  constructor(private webUiService: WebUIService) {
    this.webUiService.getParams().subscribe((res) => {
      this.clientLink = res.clientLink;
      this.authUrl = res.authUrl;
    });
  }
}
