<div
  class="menu"
  [ngStyle]="{
    display: 'flex',
    justifyContent: 'space-evenly',
    minHeight: '70px'
  }"
>
  <div
    [ngStyle]="{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }"
  >
    <div class="switch">
      <img
        height="26px"
        width="auto"
        src="assets/img/FR_FLAG.png"
        alt=""
        [ngStyle]="{ marginRight: '6px' }"
      />
      <app-meepik-switch />
      <img
        height="26px"
        width="auto"
        src="assets/img/EN_FLAG.png"
        alt=""
        [ngStyle]="{ marginLeft: '6px' }"
      />
    </div>

    <img height="20px" width="auto" src="assets/img/MEEPIK_LOGO.png" alt="" />
    <app-meepik-button
      [height]="height"
      [width]="width"
      [props]="btnProps"
      (click)="changeMenu('HOME')"
      [ngStyle]="styles.menuButton"
      routerLink="home"
      [activeAnimation]="false"
    >
      <div [ngStyle]="styles.menuButton">
        <app-meepik-home-icon
          [fillColor]="getColor('HOME')"
          [width]="'12px'"
          [height]="'12px'"
          [ngStyle]="{ paddingRight: '4px' }"
        />
        <p
          [ngStyle]="styles.menuText"
          [ngStyle]="{ color: getColor('HOME'), letterSpacing: '0.79px' }"
        >
          {{ "menu.button.home" | translate }}
        </p>
      </div>
    </app-meepik-button>
    <app-meepik-button
      [height]="height"
      [width]="width"
      [props]="btnProps"
      (click)="changeMenu('SHOP')"
      [ngStyle]="styles.menuButton"
      routerLink="/shop"
      [activeAnimation]="false"
    >
      <div [ngStyle]="styles.menuButton">
        <app-meepik-shop-icon
          [fillColor]="getColor('SHOP')"
          [width]="'12px'"
          [height]="'12px'"
          [ngStyle]="{ paddingRight: '4px' }"
        />
        <p
          [ngStyle]="styles.menuText"
          [ngStyle]="{ color: getColor('SHOP'), letterSpacing: '0.79px' }"
        >
          {{ "menu.button.pricing" | translate }}
        </p>
      </div>
    </app-meepik-button>
    <a
      [href]="pglobalParamService.clientLink"
      [ngStyle]="{
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center'
      }"
      target="_blank"
      ><app-meepik-button
        [height]="height"
        [width]="width"
        [props]="btnProps"
        (click)="changeMenu('DOWNLOAD')"
        [ngStyle]="styles.menuButton"
        [activeAnimation]="false"
      >
        <div [ngStyle]="styles.menuButton">
          <app-meepik-download-icon
            [fillColor]="getColor('DOWNLOAD')"
            [width]="'12px'"
            [height]="'12px'"
            [ngStyle]="{ paddingRight: '4px' }"
          />
          <p
            [ngStyle]="styles.menuText"
            [ngStyle]="{ color: getColor('DOWNLOAD'), letterSpacing: '0.79px' }"
          >
            {{ "menu.button.download" | translate }}
          </p>
        </div>
      </app-meepik-button></a
    >

    <app-meepik-button
      [height]="height"
      [width]="width"
      [props]="btnProps"
      (click)="changeMenu('FAQ')"
      [ngStyle]="styles.menuButton"
      routerLink="/faq"
      [activeAnimation]="false"
    >
      <div [ngStyle]="styles.menuButton">
        <p [ngStyle]="{ paddingRight: '4px', color: getColor('FAQ') }">?</p>
        <p
          [ngStyle]="styles.menuText"
          [ngStyle]="{ color: getColor('FAQ'), letterSpacing: '0.79px' }"
        >
          {{ "menu.button.faq" | translate }}
        </p>
      </div>
    </app-meepik-button>
    <div
      [ngStyle]="{
        width: '150px',
        height: '70px',
        background: ' rgba(255, 206, 87, 0.3)',
        filter: 'blur(35px)',
        position: 'absolute'
      }"
    ></div>
  </div>
  <div
    [ngStyle]="{
      width: '75px',
      height: '70px',
      background: ' rgba(255, 206, 87, 0.3)',
      filter: 'blur(35px)',
      position: 'absolute',
      right: '30%'
    }"
  ></div>
  <div [ngStyle]="{ display: 'flex' }">
    @if (isAuthenticated) {
    <a
      (click)="goProfile()"
      [ngStyle]="{
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center'
      }"
    >
      <app-meepik-button
        [height]="'40px'"
        [width]="'105px'"
        [props]="btnSingUPProps"
        [ngStyle]="styles.menuButton"
      >
        {{ "global.button.profile" | translate }}
      </app-meepik-button>
    </a>
    }

    <a
      (click)="login()"
      [ngStyle]="{
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center'
      }"
    >
      <app-meepik-button
        [height]="'40px'"
        [width]="'105px'"
        [props]="btnSingUPProps"
        [ngStyle]="styles.menuButton"
      >
        {{
          isAuthenticated
            ? ("global.button.logout" | translate)
            : ("global.button.login" | translate)
        }}
      </app-meepik-button>
    </a>
  </div>
</div>
