import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-separator',
  templateUrl: './meepik-separator.component.html',
  styleUrls: ['./meepik-separator.component.scss']
})
export class MeepikSeparatorComponent {
  @Input({ required: true }) width!: string;
}
