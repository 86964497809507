<app-meepik-separator [width]="'100%'" />
<div class="banner">
  <div [ngStyle]="{ display: 'flex', justifyContent: 'center' }">
    <div id="meepoupou" class="meePoupou"></div>
    <div
      [ngStyle]="{
        position: 'relative',
        top: '57px',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }"
    >
      <app-meepik-title
        [text]="'home.discordBanner.title' | translate"
        [textColor]="'#FFFFFF'"
        [isOutline]="false"
        [fontSize]="'64px'"
        [ngStyle]="{ paddingBottom: '18px' }"
      />
      <a
        href="https://discord.gg/hgVz3rCESk"
        target="_blank"
        style="text-decoration: none"
      >
        <app-meepik-discord-button [width]="'412px'" [height]="'71px'" />
      </a>
    </div>
    <div id="meelucid" class="meeLucid"></div>
  </div>
</div>
<app-meepik-separator [width]="'100%'" />
