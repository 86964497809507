import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-legals',
  templateUrl: './meepik-legals.component.html',
  styleUrls: ['./meepik-legals.component.scss']
})
export class MeepikLegalsComponent {

}
