import { Component } from '@angular/core';

@Component({
  selector: 'app-meepik-faq',
  templateUrl: './meepik-faq.component.html',
  styleUrls: ['./meepik-faq.component.scss'],
})
export class MeepikFaqComponent {
  questions = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
  ];
  getAnswer(questionNumber: string) : string {
    return `faq.questions.question_${questionNumber}.answer`;
  }
  getQuestionText(questionNumber: string) : string {
     return `faq.questions.question_${questionNumber}.text`;
  }
}
