import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-emotional-button',
  templateUrl: './meepik-emotional-button.component.html',
  styleUrls: ['./meepik-emotional-button.component.scss'],
})
export class MeepikEmotionalButtonComponent {
  @Input({ required: true }) width!: string;
  @Input({ required: true }) height!: string;
  @Input({ required: true }) emotion!: Emotion;

  btnProps = {
    backGroundColor: '#FFCE57',
    shadowColor: '#D27F1E',
    textColor: '#4B357F',
    font: 'spot',
    fontSize: '0',
  };
}
export type Emotion =
  | 'haha'
  | 'fear'
  | 'love'
  | 'angry'
  | 'idle'
  | 'spawn'
  | 'cry';
