<div class="container">
  <div class="sticky">
    <app-meepik-create-your-mee
      [createYourMeeOpacity]="createYourMeeOpacity"
      [thumbUpPositionLeft]="thumbUpPositionLeft"
    />
    <app-meepik-install-on-obs
      [installOBSOpacity]="installOBSOpacity"
      [meeObsInstallPositionRight]="meeObsInstallPositionRight"
    />
    <app-meepik-go-live
      [goLiveOpacity]="goLiveOpacity"
      [meeGoLivePositionBottom]="meeGoLivePositionBottom"
    />
  </div>
</div>
