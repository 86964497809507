import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-shop-icon',
  templateUrl: './meepik-shop-icon.component.svg',
  styleUrls: ['./meepik-shop-icon.component.scss'],
})
export class MeepikShopIconComponent {
  @Input() fillColor = '#FFCE57';
  @Input() width = '78px';
  @Input() height = '23px';
}
