<div class="container">
  <div class="titleArea">
    <div
      [ngStyle]="{
        display: 'flex',
        alignItems: 'baseline',
        paddingBottom: '9px'
      }"
    >
      <app-meepik-title
        [text]="'LE SHOP'"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'MEEPIk'"
        [textColor]="'#ffce57'"
        [isOutline]="true"
        [fontSize]="'45px'"
        [ngStyle]="{ paddingRight: '0.3rem' }"
      />
      <app-meepik-title
        [text]="'.'"
        [textColor]="'#ffce57'"
        [isOutline]="false"
        [fontSize]="'45px'"
      />
    </div>
    <app-meepik-separator [width]="'30px'" />
    <app-meepik-text-area>
      <div [ngStyle]="{ paddingTop: '9px', paddingRight: '30px' }">
        Unlock the full potential of Meepik for an affordable monthly
        subscription and enjoy all the emotions and avatar customization.
      </div>
    </app-meepik-text-area>
    <div class="offerArea">
      <div class="leftPart">
        <app-meepik-title
          [text]="'payment successfull'"
          [textColor]="'#ffce57'"
          [isOutline]="false"
          [fontSize]="'30px'"
        />
        <div class="whiteContainer">
          <div class="smallContainerTitle">
            <img
              src="../../../assets/img/VALIDATE_ICON.png"
              [ngStyle]="{
                width: '18px',
                height: '18px',
                paddingRight: '11px',
              }"
            />
            <p>Congratulations! Your payment was successfull.</p>
          </div>
          <p class="smallContainerTextDescription">
            Welcome to the Mee family !
          </p>
        </div>
      </div>
      <div class="rightPart">
        <img
          [ngStyle]="{
            position: 'absolute',
            bottom: 0,
            left: '-31px',
            transform: 'scale(-1, 1)'
          }"
          src="../../../assets/img/SHOP_MEE_MANGA.png"
          alt=""
        />
      </div>
    </div>
  </div>
</div>
