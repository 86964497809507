import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-create-your-mee',
  templateUrl: './meepik-create-your-mee.component.html',
  styleUrls: ['./meepik-create-your-mee.component.scss'],
})
export class MeepikCreateYourMeeComponent {
  @Input() createYourMeeOpacity: number = 1;
  @Input() thumbUpPositionLeft: string = '0px';
  viewHeight = window.visualViewport?.height;
}
