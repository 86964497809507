import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-live-user-button',
  templateUrl: './meepik-live-user-button.component.html',
  styleUrls: ['./meepik-live-user-button.component.scss'],
})
export class MeepikLiveUserButtonComponent {
  @Input() cardInfo: UserTwitchCard = {
    name: 'YOOYOO',
    img: '',
    isOnline: false,
    id: '01',
    twitchlink: 'https://twitch.tv',
  };
}
export type UserTwitchCard = {
  isOnline: boolean;
  name: string;
  img: string;
  id: string;
  twitchlink: string;
};
