import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-discord-button',
  templateUrl: './meepik-discord-button.component.html',
  styleUrls: ['./meepik-discord-button.component.scss'],
})
export class MeepikDiscordButtonComponent {
  @Input({ required: true }) width!: string;
  @Input({ required: true }) height!: string;

  btnProps = {
    backGroundColor: '#FFFFFF',
    shadowColor: '#5865F2',
    textColor: '#5865F2',
    font: 'Spot',
    fontSize: '32px',
  };
}
