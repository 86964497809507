import { Component } from '@angular/core';
import { MeepikUserService } from 'src/api/helper/userService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private userService: MeepikUserService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    translate.use(this.getLang());
  }
  getLang(): string {
    const savedLang = localStorage.getItem('meepik_lang');
    return savedLang !== null && (savedLang === 'fr' || savedLang === 'en')
      ? savedLang
      : 'en';
  }

  ngOnInit() {
    this.userService.checkAuth();
    this.userService.checkSessionChanged();
  }
}
