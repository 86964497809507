import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-meepik-windows-icon',
  templateUrl: './meepik-windows-icon.component.svg',
  styleUrls: ['./meepik-windows-icon.component.scss']
})
export class MeepikWindowsIconComponent {
  @Input() fillColor = 'white';
  @Input() width = '19px';
  @Input() height = '19px';
}
