import { Component } from '@angular/core';
import {
  GetTwitchUserInfoResponse,
  TwitchApiService,
  TwitchUserInfo,
  TwitchUserOnlineInfo,
  GetTwitchUserOnlineInfoResponse,
  GetAuthTokenResponse,
} from 'src/api/twitch/twitchService';
import { UserTwitchCard } from 'src/app/molecules/meepik-live-user-button/meepik-live-user-button.component';

@Component({
  selector: 'app-meepik-live-users',
  templateUrl: './meepik-live-users.component.html',
  styleUrls: ['./meepik-live-users.component.scss'],
})
export class MeepikLiveUsersComponent {
  userInfos: TwitchUserInfo[] = [];
  userOnlineInfos: TwitchUserOnlineInfo[] = [];
  constructor(twitchApiService: TwitchApiService) {
    twitchApiService.getAuthToken().subscribe((res: GetAuthTokenResponse) => {
      twitchApiService.authorization = res.access_token;
      twitchApiService
        .getTwitchUserInfo()
        .subscribe((res: GetTwitchUserInfoResponse) => {
          this.userInfos = res.data;
          twitchApiService
            .getTwitchUserOnlineInfo()
            .subscribe((res: GetTwitchUserOnlineInfoResponse) => {
              this.userOnlineInfos = res.data;
              this.userInfos.forEach((userInfo) => {
                this.cards.push({
                  id: userInfo.id,
                  img: userInfo.profile_image_url,
                  name: userInfo.display_name,
                  isOnline: this.isUserOnline(userInfo.id),
                  twitchlink: `https://twitch.tv/${userInfo.login}`,
                });
              });
            });
        });
    });
  }
  isUserOnline(userId: string) {
    return (
      this.userOnlineInfos?.find((user) => user.user_id === userId) !==
        undefined ?? false
    );
  }
  cards: UserTwitchCard[] = [];
}
