import { Component } from '@angular/core';
import { WebUIService } from 'src/api/webui/webuiApi';
import { environment } from 'src/environments/environment';
import Stripe from 'stripe';

@Component({
  selector: 'app-meepik-payment-success',
  templateUrl: './meepik-payment-success.component.html',
  styleUrls: ['./meepik-payment-success.component.scss'],
})
export class MeepikPaymentSuccessComponent {
  constructor(private webuiService: WebUIService) {}
  ngOnInit() {
    this.retrieveSession();
  }
  retrieveSession() {
    let currentUrl = new URL(window.location.href);
    let sessionId = currentUrl.searchParams.get('session_id');
    if (sessionId !== null) {
      this.webuiService.createSubscription(sessionId).subscribe();
    }
  }
}
